import React, { useEffect, useState } from "react";
import { X, AlertTriangle, Clock, FileText, Globe, Shield, Briefcase, Rocket, Video, Mic, Check, ArrowRight, ChevronLeft } from "lucide-react";

const InterviewGuideModal = ({ isOpen, onClose, onStart }) => {
  const [step, setStep] = useState(1);
  const [acknowledged, setAcknowledged] = useState(false);
  
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  // Reset steps when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setStep(1);
      setAcknowledged(false);
    }
  }, [isOpen]);

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const goToPreviousStep = () => {
    setStep(step - 1);
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-900/40 backdrop-blur-sm flex items-center justify-center p-4 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl p-6 max-w-xl w-full shadow-xl relative transform transition-all duration-300"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors p-1 hover:bg-gray-100 rounded-md"
        >
          <X size={20} className="stroke-[1.5]" />
        </button>

        {/* Step indicator */}
        <div className="w-full mb-6">
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-50">
                  Step {step} of 3
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-50">
              <div
                style={{ width: `${(step / 3) * 100}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-600 transition-all duration-500"
              ></div>
            </div>
          </div>
        </div>

        {/* Step 1: Introduction */}
        {step === 1 && (
          <div className="flex flex-col items-center text-center space-y-5">
            <div className="p-4 bg-indigo-100/80 rounded-full mb-2">
              <Rocket className="w-10 h-10 text-indigo-600" strokeWidth={1.5} />
            </div>

            <div className="space-y-4 w-full">
              <h3 className="text-xl font-semibold text-gray-900 mb-1">AI-Powered Mock Interview</h3>

              <div className="text-gray-600 text-sm space-y-4 text-left ml-3">
                {[
                  { Icon: Clock, text: "15-minute assessment analyzing your resume-based skills" },
                  { Icon: Briefcase, text: "Required for profile publishing on Talent Hub" },
                  { Icon: Shield, text: "Results shared with recruiters to showcase your expertise" },
                ].map((item, idx) => (
                  <div key={idx} className="flex items-start gap-3">
                    <item.Icon className="w-5 h-5 text-indigo-600 flex-shrink-0 mt-0.5" />
                    <span className="leading-snug">{item.text}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Warning Banner - One-time attempt */}
            <div className="w-full bg-indigo-50 p-3 rounded-lg flex gap-2 items-start border border-indigo-100">
              <AlertTriangle className="w-5 h-5 text-indigo-600 flex-shrink-0 mt-0.5" />
              <div className="text-indigo-800 text-sm text-left">
                <p className="font-medium">One-time only attempt</p>
                <p>You have only one chance to make an impression, so give it your best shot and nail it like a pro!</p>
              </div>
            </div>

            {/* Action Button */}
            <div className="flex w-full mt-4">
              <button
                onClick={goToNextStep}
                className="w-full px-4 py-2.5 bg-indigo-600 text-white font-medium rounded-full hover:bg-indigo-700 transition-colors shadow-sm hover:shadow-md flex items-center justify-center gap-2"
              >
                Continue
                <ArrowRight size={18} />
              </button>
            </div>
          </div>
        )}

        {/* Step 2: Requirements */}
        {step === 2 && (
          <div className="flex flex-col items-center text-center space-y-5">
            <div className="p-4 bg-indigo-100/80 rounded-full mb-2">
              <Shield className="w-10 h-10 text-indigo-600" strokeWidth={1.5} />
            </div>

            <div className="space-y-4 w-full">
              <h3 className="text-xl font-semibold text-gray-900 mb-1">Interview Requirements</h3>
              <p className="text-gray-600 text-sm">
                Please ensure you have the following before starting your interview:
              </p>
            </div>

            {/* Requirements Grid */}
            <div className="w-full space-y-3">
              <div className="grid grid-cols-2 gap-4">
                {[
                  { Icon: Globe, text: "Stable Internet", key: "internet", description: "A reliable connection ensures uninterrupted recording" },
                  { Icon: FileText, text: "Resume Ready", key: "resume", description: "Have your resume handy for reference" },
                  { Icon: Briefcase, text: "Quiet Space", key: "space", description: "Find a quiet area with minimal background noise" },
                  { Icon: Shield, text: "No Interruptions", key: "privacy", description: "Ensure you won't be disturbed during the session" },
                ].map((item) => (
                  <div
                    key={item.key}
                    className="flex flex-col items-center p-4 bg-white rounded-lg border border-gray-200 hover:border-indigo-200 transition-colors"
                  >
                    <div className="p-2 bg-indigo-50 rounded-full mb-2">
                      <item.Icon className="w-6 h-6 text-indigo-600" />
                    </div>
                    <span className="text-sm font-medium text-gray-700">{item.text}</span>
                    <p className="text-xs text-gray-500 mt-2">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-3 w-full mt-4">
              <button
                onClick={goToPreviousStep}
                className="px-4 py-2.5 text-gray-700 font-medium rounded-full hover:bg-gray-50 transition-colors border border-gray-300 flex items-center justify-center gap-2"
              >
                <ChevronLeft size={18} />
                Back
              </button>
              <button
                onClick={goToNextStep}
                className="flex-1 px-4 py-2.5 bg-indigo-600 text-white font-medium rounded-full hover:bg-indigo-700 transition-colors shadow-sm hover:shadow-md flex items-center justify-center gap-2"
              >
                Continue
                <ArrowRight size={18} />
              </button>
            </div>
          </div>
        )}

        {/* Step 3: Important Warning */}
        {step === 3 && (
          <div className="flex flex-col items-center text-center space-y-5">
            <div className="p-4 bg-indigo-100 rounded-full mb-2">
              <AlertTriangle className="w-10 h-10 text-indigo-600" strokeWidth={1.5} />
            </div>

            <div className="space-y-2 w-full">
              <h3 className="text-xl font-semibold text-gray-900 mb-1">Important Notice</h3>
              <p className="text-indigo-600 font-medium text-sm">
                Please read and acknowledge the following before proceeding
              </p>
            </div>

            {/* NEW: Recording Warning Banner */}
            <div className="w-full bg-yellow-50 p-4 rounded-lg border border-yellow-200">
              <div className="flex gap-3 items-start">
                <div className="text-left w-full">
                  <ul className="space-y-4 text-sm text-yellow-700">
                    <li className="flex items-start gap-3">
                      <Video className="w-5 h-5 mt-0.5 flex-shrink-0 text-yellow-600" />
                      <div>
                        <span className="font-semibold">Video Recording</span>
                        <p>This interview is recorded (video + audio).</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-3">
                      <Mic className="w-5 h-5 mt-0.5 flex-shrink-0 text-yellow-600" />
                      <div>
                        <span className="font-semibold">Video Required</span>
                        <p>Submissions without video will not be shared with recruiters.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-3">
                      <Shield className="w-5 h-5 mt-0.5 flex-shrink-0 text-yellow-600" />
                      <div>
                        <span className="font-semibold">Professional Conduct</span>
                        <p>Recruiters will evaluate both video and transcript - maintain professionalism.</p>
                      </div>
                    </li>
                    <li className="flex items-start gap-3">
                      <AlertTriangle className="w-5 h-5 mt-0.5 flex-shrink-0 text-yellow-600" />
                      <div>
                        <span className="font-semibold">No Second Chance</span>
                        <p>If you quit, your profile will not be shared and you cannot reapply.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              
              {/* Acknowledgment checkbox */}
              <div className="mt-6 flex items-center gap-3 justify-center border-t border-yellow-200 pt-4">
                <button 
                  onClick={() => setAcknowledged(!acknowledged)}
                  className="flex items-center gap-3 text-sm font-medium text-yellow-700"
                >
                  <div className={`w-6 h-6 rounded-md border-2 flex items-center justify-center transition-colors ${acknowledged ? 'bg-yellow-600 border-yellow-600' : 'border-yellow-400'}`}>
                    {acknowledged && <Check className="w-4 h-4 text-white" />}
                  </div>
                  <span>I understand and acknowledge all conditions</span>
                </button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex gap-3 w-full mt-4">
              <button
                onClick={goToPreviousStep}
                className="px-4 py-2.5 text-gray-700 font-medium rounded-full hover:bg-gray-50 transition-colors border border-gray-300 flex items-center justify-center gap-2"
              >
                <ChevronLeft size={18} />
                Back
              </button>
              <button
                onClick={onStart}
                disabled={!acknowledged}
                className={`flex-1 px-4 py-2.5 font-medium rounded-full shadow-sm transition-all ${
                  acknowledged 
                    ? 'bg-indigo-600 text-white hover:bg-indigo-700 hover:shadow-md' 
                    : 'bg-gray-200 text-gray-500 cursor-not-allowed'
                }`}
              >
                Begin Interview
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterviewGuideModal;