import React from 'react';
import { motion } from 'framer-motion';
import { BsCheck, BsCheck2, BsCheckCircle } from 'react-icons/bs';

const defaultSteps = [
  { label: "Upload Resume", duration: "2 mins" },
  { label: "Complete Profile", duration: "5 mins" },
  { label: "Take Interview", duration: "15 mins" },
];

const Stepper = ({ currentStep = 0, steps = defaultSteps }) => {
  return (
    <div className="pt-2 pb-4">
      <div className="max-w-4xl mx-auto px-4 sm:px-6">
        <div className="relative flex justify-between items-center">
          {/* Progress Line */}
          <div className="absolute top-4 left-0 right-0 h-0.5 bg-gray-100 mx-4 md:mx-12">
            <motion.div
              className="h-full bg-indigo-500"
              initial={{ width: 0 }}
              animate={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            />
          </div>

          {steps.map((step, index) => (
            <div key={index} className="relative z-10 flex flex-col items-center">
              {/* Step Indicator */}
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: index <= currentStep ? 1 : 1 }}
                className={`relative flex items-center justify-center
                  ${index <= currentStep ? "text-white" : "text-gray-400"}`}
              >
                <div className={`h-9 w-9 rounded-full flex items-center justify-center
                  ${index < currentStep 
                    ? "bg-indigo-500 shadow-lg shadow-indigo-200" 
                    : index === currentStep 
                      ? "bg-indigo-500 ring-4 ring-indigo-100" 
                      : "bg-gray-200/70"}`}
                >
                  {index < currentStep ? (
                    <BsCheck className="w-6 h-6" />
                  ) : (
                    <span className={`text-sm font-medium
                      ${index === currentStep ? "text-white" : "text-gray-400"}`}>
                      {index + 1}
                    </span>
                  )}
                </div>
              </motion.div>

              {/* Step Labels */}
              <div className="text-center mt-4">
                <p className={`text-sm font-medium transition-colors duration-200
                  ${index <= currentStep ? "text-gray-900" : "text-gray-400"}`}>
                  {step.label}
                </p>
                <p className="text-xs text-gray-400 mt-1">{step.duration}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;