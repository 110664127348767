import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserById } from '../../Services/ApiEnpoints';

// Async thunk to fetch user data
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      const errorMessage = "Unauthorized: No token provided";
      console.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
    try {
      // Correctly assign the returned data
      const data = await getUserById({
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!data) {
        const errorMessage = "No data received from API";
        console.error(errorMessage);
        return rejectWithValue(errorMessage);
      }

      if (typeof data.fullName === 'undefined') {
        const errorMessage = "User data is missing property 'fullName'";
        console.error(errorMessage, data);
        return rejectWithValue(errorMessage);
      }

      return {
        fullName: data.fullName,
        onboardingStep: data.onboardingStep,
        email: data.email,
        profile_id: data.profile_id,
      };
    } catch (error) {
      console.error("Error fetching user:", error);
      return rejectWithValue(
        error.response?.data?.message || error.message || "An error occurred"
      );
    }
  }
);


const initialState = {
  id: null,
  email: '',
  fullName: '',
  profilePictureUrl: '',
  onboardingStep: 1,
  profile_id: null,
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return { ...state, ...action.payload };
    },
    updateProfilePicture(state, action) {
      state.profilePictureUrl = action.payload;
    },
    setOnboardingStep(state, action) {
      state.onboardingStep = action.payload;
    },
    clearUser() {
      return initialState;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fullName = action.payload.fullName;
        state.onboardingStep = action.payload.onboardingStep;
        state.email = action.payload.email;
        state.profile_id = action.payload.profile_id;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        console.error("fetchUser rejected:", action.payload);
      });
  },
});

export const {
  setUser,
  updateProfilePicture,
  setOnboardingStep,
  clearUser,
  resetState,
} = userSlice.actions;

export default userSlice.reducer;
