import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import buddyReducer from "../Slices/BuddySlice";
import toastReducer from "../Slices/toastSlice";
import userReducer from "../Slices/userSlice";
import pdfReducer from "../Slices/pdfSlice";
import appReducer from "../Slices/appSlice";
import profileReducer from "../Slices/profileSlice";
import referralReducer from "../Slices/referralSlice";

// ✅ Correct way to create the persisted root reducer
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["buddy", "toastSliceInfo", "user", "pdf", "app", "profile"], // ✅ Everything is persisted
};

const rootReducer = combineReducers({
  buddy: buddyReducer,
  toastSliceInfo: toastReducer,
  user: userReducer,
  pdf: pdfReducer,
  app: appReducer,
  profile: profileReducer,
  referral: referralReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Avoid warnings from Redux Persist
    }),
});

export const persistor = persistStore(store);
