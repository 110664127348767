// src/data/templates.js

import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import React from 'react'; // Ensure React is imported to use JSX in data
import EmojiEmotionsOutlined from '@mui/icons-material/EmojiEmotionsOutlined';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import TrendingUpOutlined from '@mui/icons-material/TrendingUpOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import GroupsOutlined from '@mui/icons-material/GroupsOutlined';


export const templates = {
    'google-googlyness': {
        title: "Googlyness Mock Interview",
        description: "Showcase how you align with Google's culture of innovation and teamwork.",
        difficulty: "Medium",
        tips: [
            {
                text: "Be authentic. Showcase your unique personality.",
                icon: <EmojiEmotionsOutlinedIcon />
            },
            {
                text: "Think aloud when solving problems. Share your reasoning.",
                icon: <PsychologyOutlinedIcon />
            },
            {
                text: "Ensure a stable internet connection.",
                icon: <WifiOutlinedIcon />
            },
            {
                text: "Ensure a quiet environment.",
                icon: <VolumeOffOutlinedIcon />
            },
            {
                text: "Focus on your thought process over specific answers.",
                icon: <TipsAndUpdatesOutlinedIcon />
            }
        ],
        disclaimer: "Please note: This mock interview is for practice, not affiliated with or endorsed by Google, and is based on publicly shared questions."
    },
    'amazon-leadership-principles': {
        title: "Amazon’s Leadership Principles",
        description: "Test your familiarity with Amazon's Leadership Principles.",
        difficulty: "Medium",
        tips: [
            {
                text: "Provide specific examples of past leadership experiences.",
                icon: <EmojiEmotionsOutlinedIcon />
            },
            {
                text: "Show how you handle conflicts and challenges.",
                icon: <PsychologyOutlinedIcon />
            },
            {
                text: "Maintain a professional environment.",
                icon: <WifiOutlinedIcon />
            },
            {
                text: "Minimize background noise for clarity.",
                icon: <VolumeOffOutlinedIcon />
            },
            {
                text: "Emphasize strategic thinking and decision-making.",
                icon: <TipsAndUpdatesOutlinedIcon />
            }
        ],
        disclaimer: "Please note: This mock interview is for practice, not affiliated with or endorsed by Amazon, and is based on common leadership questions."
    },
    'meta-product-sense-interviews': {
    title: "Meta’s Product Sense Interviews",
    description: "Solve problems by designing innovative product solutions.",
    difficulty: "Medium",
    tips: [
        {
            text: "Focus on user empathy when solving problems.",
            icon: <EmojiEmotionsOutlined />
        },
        {
            text: "Break down product ideas into actionable steps.",
            icon: <CategoryOutlined />
        },
        {
            text: "Consider scalability and user impact in your designs.",
            icon: <TrendingUpOutlined />
        },
        {
            text: "Communicate your ideas clearly and concisely.",
            icon: <ChatOutlined />
        },
        {
            text: "Leverage Meta’s focus on community and connection.",
            icon: <GroupsOutlined />
        }
    ],
    disclaimer: "Please note: This mock interview is for practice, not affiliated with or endorsed by Meta, and is based on common product sense questions."
}

    // Add more templates as needed
};
