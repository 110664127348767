import { useState, useEffect, useRef } from "react";
import { X, Upload, FileText, CheckCircle, AlertCircle, FileIcon, Clock } from "lucide-react";
import { processCV } from "../../Services/ApiEnpoints";

export default function CVUploadModal({ isOpen, onClose, user_id }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadState, setUploadState] = useState('idle'); // idle, uploading, analyzing, success, error
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [processingMessage, setProcessingMessage] = useState("We're analyzing your CV...");
  const timerRef = useRef(null);

  // Reset states when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(null);
      setUploadState('idle');
      setError(null);
      setSuccessMessage("");
      setProcessingMessage("We're analyzing your CV...");
      
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [isOpen]);

  // Set up message timer when in analyzing state
  useEffect(() => {
    if (uploadState === 'analyzing') {
      timerRef.current = setTimeout(() => {
        setProcessingMessage("We're almost there! Getting your CV ready...");
      }, 15000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [uploadState]);

  const handleFileChange = (event) => {
    // Prevent file change if processing is ongoing
    if (uploadState !== 'idle') return;

    const file = event.target.files[0];

    if (file) {
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please upload a PDF or DOCX file.");
        setSelectedFile(null);
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError("File size exceeds 5MB. Please choose a smaller file.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      setError(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Please select a file first.");
      return;
    }

    // Start upload process
    setUploadState('uploading');
    setError(null);
    setSuccessMessage("");

    try {
      // Simulate network delay for uploading
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      setUploadState('analyzing');
      const response = await processCV(selectedFile);
      const onboardingStatus = response?.result?.onboardingStatus;

      if (!onboardingStatus) {
        console.error("Unexpected response structure:", response);
        setError("Unexpected response from the server.");
        setUploadState('error');
        return;
      }

      const resumeData = onboardingStatus.resumeUploaded;

      if (typeof resumeData === "object") {
        if (resumeData.error) {
          setError(resumeData.error);
          setUploadState('error');
          return;
        }

        if (!resumeData.status) {
          setError("Resume processing failed. Please upload a valid resume.");
          setUploadState('error');
          return;
        }
      } else if (resumeData !== true) {
        setError("Unexpected response from the server.");
        setUploadState('error');
        return;
      }

      setUploadState('success');
      setSuccessMessage("CV uploaded successfully!");
    } catch (err) {
      console.error("Upload failed:", err);
      setError("Upload failed. Please try again.");
      setUploadState('error');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 backdrop-blur-md">
      <div
        onClick={onClose}
        className="fixed inset-0 bg-black/40 flex items-center justify-center p-6 transition-all duration-300"
      >
        <div
          className="bg-white rounded-3xl shadow-2xl w-full max-w-2xl transform transition-all duration-300 overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex justify-between items-center px-8 py-6 border-b">
            <div className="flex items-center gap-4">
              <div className={`p-3 rounded-xl ${uploadState === 'success' ? 'bg-indigo-50' : 'bg-indigo-50'}`}>
                {uploadState === 'success' ? (
                  <CheckCircle className="h-8 w-8 text-indigo-500" />
                ) : (
                  <FileText className="h-8 w-8 text-indigo-600" />
                )}
              </div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-900">
                  {uploadState === 'analyzing' ? "Processing Your CV" : 
                   uploadState === 'success' ? "CV Upload Successful" : 
                   "Upload Your CV"}
                </h3>
                <p className="text-gray-500">
                  {uploadState === 'analyzing' ? "Please wait while we analyze your document" : 
                   uploadState === 'success' ? "Your professional profile has been updated" : 
                   "Let recruiters see your professional journey"}
                </p>
              </div>
            </div>
            {(uploadState !== 'analyzing') && (
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-xl transition-colors"
                aria-label="Close"
              >
                <X className="h-6 w-6 text-gray-500" />
              </button>
            )}
          </div>

          {/* Content */}
          <div className="p-8 space-y-8">
            {uploadState === 'analyzing' ? (
              /* Processing State */
              <div className="flex flex-col items-center justify-center py-12 text-center space-y-8">
                <div className="relative">
                  <div className="w-24 h-24 border-4 border-indigo-200 rounded-full"></div>
                  <div className="w-24 h-24 border-4 border-indigo-600 border-t-transparent rounded-full absolute top-0 left-0 animate-spin"></div>
                </div>
                
                <div className="space-y-2 max-w-md">
                  <h4 className="text-xl font-medium text-gray-900">{processingMessage}</h4>
                  <p className="text-gray-500">
                    We're extracting key information from your CV to help you stand out to recruiters.
                  </p>
                </div>
                
                <div className="flex items-center gap-2 text-sm text-indigo-600 font-medium">
                  <Clock className="h-4 w-4" />
                  <span>This usually takes less than a minute</span>
                </div>
              </div>
            ) : uploadState === 'success' ? (
              /* Success State */
              <div className="flex flex-col items-center justify-center py-12 text-center space-y-8">
                <div className="w-24 h-24 bg-indigo-50 rounded-full flex items-center justify-center">
                  <CheckCircle className="h-14 w-14 text-indigo-500" />
                </div>
                
                <div className="space-y-3 max-w-md">
                  <h4 className="text-2xl font-bold text-gray-900">CV Uploaded Successfully!</h4>
                  <p className="text-gray-600">
                    Your CV has been processed and is now ready for recruiters to view. Your profile has been updated with information from your CV.
                  </p>
                </div>
                
                <div className="flex flex-col items-center gap-4 pt-4">
                  <div className="px-6 py-4 bg-gray-50 rounded-xl border border-gray-100 w-full max-w-md">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 mt-1">
                        <FileIcon className="h-6 w-6 text-indigo-600" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">{selectedFile?.name}</p>
                        <p className="text-xs text-gray-500">{(selectedFile?.size / (1024 * 1024)).toFixed(2)} MB</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            ) : (
              /* Normal Upload State */
              <>
                {/* Guidelines */}
                <div className="grid grid-cols-2 gap-6">
                  <div className="flex items-center gap-3 p-4 bg-white rounded-xl border shadow-sm">
                    <CheckCircle className="h-6 w-6 text-indigo-600 flex-shrink-0" />
                    <span className="text-gray-600 font-medium">PDF files accepted</span>
                  </div>
                  <div className="flex items-center gap-3 p-4 bg-white rounded-xl border shadow-sm">
                    <AlertCircle className="h-6 w-6 text-indigo-600 flex-shrink-0" />
                    <span className="text-gray-600 font-medium">Maximum size: 5MB</span>
                  </div>
                </div>

                {/* Upload Area */}
                <div className="group relative">
                  <label
                    htmlFor="cv-upload"
                    className={`flex flex-col items-center justify-center w-full p-8 border-2 border-dashed rounded-xl cursor-pointer transition-all
                      ${selectedFile
                        ? "border-gray-300 bg-gray-50"
                        : "border-gray-200 hover:border-gray-400 bg-white"
                      }
                      ${uploadState !== 'idle' && "pointer-events-none opacity-70"}`}
                  >
                    <div className="flex flex-col items-center space-y-4">
                      {selectedFile ? (
                        <div className="p-4 bg-indigo-100 rounded-2xl">
                          <FileIcon className="h-12 w-12 text-indigo-600" />
                        </div>
                      ) : (
                        <div className="p-4 bg-white rounded-2xl shadow-sm border-2 border-gray-100">
                          <Upload className="h-12 w-12 text-gray-400" />
                        </div>
                      )}

                      <div className="text-center">
                        {selectedFile ? (
                          <>
                            <p className="text-lg font-medium text-indigo-600">
                              {selectedFile.name}
                            </p>
                            <p className="text-sm text-gray-500 mt-1">
                              {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB • Click to change file
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="text-lg font-medium text-gray-700">
                              Drag & drop your CV here or{" "}
                              <span className="text-indigo-600">browse files</span>
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                              Supported formats: PDF, DOCX
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                  <input
                    id="cv-upload"
                    type="file"
                    className="hidden"
                    accept=".pdf,.docx"
                    onChange={handleFileChange}
                    disabled={uploadState !== 'idle'} // Disable file input when processing
                  />
                </div>

                {/* Messages */}
                {error && (
                  <div className="p-4 bg-red-50 rounded-xl flex items-center gap-3">
                    <AlertCircle className="h-5 w-5 text-red-500" />
                    <p className="text-red-700">{error}</p>
                  </div>
                )}
              </>
            )}
          </div>

          {/* Footer */}
          {uploadState !== 'analyzing' && (
            <div className="px-8 py-6 bg-gray-50 flex justify-end gap-4">
              {uploadState === 'success' ? (
                <button
                  onClick={onClose}
                  className="px-8 py-3 text-sm font-medium bg-indigo-600 text-white hover:bg-indigo-700 rounded-xl transition-colors shadow-lg"
                >
                  Continue to Profile
                </button>
              ) : (
                <>
                  <button
                    onClick={onClose}
                    className="px-6 py-3 text-sm bg-gray-100 font-medium text-gray-600 hover:bg-gray-200 rounded-xl transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleUpload}
                    disabled={!selectedFile || uploadState !== 'idle'}
                    className={`px-8 py-3 text-sm font-medium rounded-xl transition-all
                    ${
                      // If a file is selected, always use the active styling even when processing
                      selectedFile
                        ? "bg-indigo-700 text-white hover:bg-indigo-800 shadow-lg"
                        : "bg-gray-100 text-gray-400 cursor-not-allowed"
                      }`}
                  >
                    {uploadState === 'uploading' && (
                      <span className="flex items-center gap-2">
                        <div className="animate-spin rounded-full h-4 w-4 border-2 border-gray-50 border-t-transparent"></div>
                        Uploading CV...
                      </span>
                    )}
                    {uploadState === 'idle' && "Upload CV"}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}