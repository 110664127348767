import React from 'react';
import asendia_logo from '../../../assests/Images/asendia_logo.png'; // adjust the path as needed
const NavBar = ({ links = [] }) => {
 
  return (
    <nav className="w-full flex items-center justify-between px-4 sm:px-6 py-3 bg-white/0  sticky top-0 z-50">
      <div className="flex items-center">
        <img src={asendia_logo} alt="Logo" className="h-16 w-auto" />
      </div>
      <div className="flex items-center space-x-4">
        {links.map((link, index) => (
          <button
            key={index}
            onClick={link.onClick}
            className={`px-4 py-2 text-sm font-normal rounded-full  transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 ${index === 0
              ? "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
              : "text-gray-800 bg-white border border-gray-200/80 hover:bg-gray-50 focus:ring-gray-300"
              }`}
          >
            {link.label}
          </button>
        ))}


      </div>
    </nav>
  );
};

export default NavBar;
