import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserReferralData, generateReferral } from "../../Services/ApiEnpoints";

// Async action to fetch an existing referral link
export const fetchReferralLink = createAsyncThunk(
  "referral/fetchReferralLink",
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetchUserReferralData();
      return data?.referral_link || ""; // Return the referral link if it exists
    } catch (error) {
      return rejectWithValue("Failed to load referral link");
    }
  }
);

// Async action to generate a new referral link
export const createReferralLink = createAsyncThunk(
  "referral/createReferralLink",
  async (_, { rejectWithValue }) => {
    try {
      const res = await generateReferral();
      return res?.referral_link;
    } catch (error) {
      return rejectWithValue("Failed to generate referral link");
    }
  }
);

const referralSlice = createSlice({
  name: "referral",
  initialState: {
    referralLink: "",
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferralLink.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchReferralLink.fulfilled, (state, action) => {
        state.referralLink = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchReferralLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createReferralLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReferralLink.fulfilled, (state, action) => {
        state.referralLink = action.payload;
        state.isLoading = false;
      })
      .addCase(createReferralLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default referralSlice.reducer;
