import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { processCV, updateOnboardingStatus, updateOnboardingStep } from '../../Services/ApiEnpoints';
import { setOnboardingStep } from '../../Redux/Slices/userSlice';
import { BsBoxArrowRight, BsCloudArrowUp, BsQuestionCircle } from 'react-icons/bs';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { motion } from "framer-motion";
import NavBar from './components/NavBar';
import Stepper from './components/Stepper';
import { clearProfile } from '../../Redux/Slices/profileSlice';
import { clearUser } from '../../Redux/Slices/userSlice';
import { resetState as resetBuddyState } from '../../Redux/Slices/BuddySlice';
import { resetState as resetPdfState } from '../../Redux/Slices/pdfSlice';
import { resetState as resetUserState } from '../../Redux/Slices/userSlice';

const DocIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M14 4v4h-4V4H6v16h12V4h-4zm-2 10h2v2h-2v-2zm0-4h2v2h-2v-2zm-4 0h2v2H8v-2z" />
  </svg>
);

const OnboardingStep1 = ({ setFeedback, feedback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { email } = user;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [cv, setCv] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [processingComplete, setProcessingComplete] = useState(false);
  const [showAlmostThere, setShowAlmostThere] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false); // New state for error modal
  const [errorDetails, setErrorDetails] = useState(""); // New state for detailed error message

  useEffect(() => {
    let timer;
    if (processing && !processingComplete) {
      timer = setTimeout(() => {
        setShowAlmostThere(true);
      }, 12000);
    }
    return () => clearTimeout(timer);
  }, [processing, processingComplete]);

  useEffect(() => {
    updateOnboardingStatus();
  }, []);

  useEffect(() => {
    if (cv) {
      const url = URL.createObjectURL(cv);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [cv]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      setCv(file);
      setError("");
    } else {
      setError("Please upload a PDF or Word document");
      setCv(null);
    }
  };

  const handleNext = async () => {
    if (!cv) {
      setError("Please upload your CV before proceeding.");
      return;
    }

    setButtonLoading(true);
    setProcessing(true);

    try {
      const response = await processCV(cv, true);

      console.log("CV Processing Data:", response);

      if (!response || !response.result) {
        console.error("Invalid response structure.");
        setError("Unexpected error occurred. Please try again.");
        return;
      }

      const { result } = response;

      if (result.cvParsedSuccessfully === false) {
        setError("Resume processing failed. Please try again.");
        setErrorDetails(result.message || "No additional details provided.");
        setProcessing(false);
        setButtonLoading(false);
        return;
      }

      const newStep = "2";
      await updateOnboardingStep(newStep, email);
      dispatch(setOnboardingStep(newStep));
      setProcessingComplete(true);
    } catch (err) {
      console.error("CV processing failed:", err);
      setError("Resume processing failed. Please try again.");
      setErrorDetails(err.message || "Failed to process your CV.");
    } finally {
      setButtonLoading(false);
      if (processingComplete) setProcessing(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    dispatch(resetBuddyState());
    dispatch(clearProfile());
    dispatch(resetPdfState());
    dispatch(resetUserState());
    localStorage.clear();
    sessionStorage.clear();
    dispatch(clearUser());
    navigate("/signin");
  };

  return (
    <div className="min-h-screen bg-white overflow-auto relative">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <NavBar
          links={[
            { label: <span><BsQuestionCircle className="inline mr-1" /> Submit feedback</span>, onClick: () => setFeedback(true) },
            { label: <span><BsBoxArrowRight className="inline mr-1" /> Logout</span>, onClick: handleLogout },
          ]}
        />

        <div className="-mt-3 mb-4">
          <Stepper currentStep={0} />
        </div>
        <div className="w-full text-center mt-2">
          <div className="text-xl sm:text-2xl md:text-3xl font-semibold">
            Reach Thousands of Jobs with<span className="ml-2 text-indigo-600">ONE</span> AI Interview!
          </div>
          <div className="text-gray-500 text-base mt-2">
            Join 10K+ top talents landing high-paying remote jobs worldwide 🌎
          </div>
        </div>

        <div className="flex-1 flex items-center justify-center px-4 sm:px-6 py-8 bg-white">
          <div className="w-full max-w-2xl bg-white rounded-[2rem] shadow-lg shadow-gray-300/30 border border-gray-300/50 p-8 sm:p-14 transition-all hover:shadow-xl hover:shadow-gray-300/40 relative overflow-hidden">
            <div className="mb-8 text-center relative">
              <h2 className="text-2xl font-bold text-gray-900 mb-2">
                Launch Your Career
              </h2>
              <p className="text-gray-500 text-sm font-normal">
                Upload your resume to unlock personalized opportunities
              </p>
            </div>

            <div className="group relative border-2 border-dashed border-gray-200/80 bg-white rounded-xl p-5 transition-all hover:border-indigo-400 hover:bg-indigo-50/20 hover:scale-[1.02]">
              {cv ? (
                <div className="text-center w-full space-y-5">
                  <div className="relative mx-auto w-fit">
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-purple-400 blur-lg opacity-20 -z-10" />
                    <div className="p-3 bg-gray-100/80 rounded-xl shadow-sm border border-gray-200/80 backdrop-blur-sm">
                      {cv.type === "application/pdf" ? (
                        <AiOutlineFilePdf className="h-10 w-10 text-red-500 drop-shadow" />
                      ) : (
                        <DocIcon className="h-10 w-10 text-indigo-500 drop-shadow" />
                      )}
                    </div>
                  </div>
                  <p className="text-gray-700 font-medium text-sm truncate px-4">
                    {cv.name}
                  </p>
                  <div className="flex justify-center space-x-4">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowPreview(true);
                      }}
                      className="text-indigo-600 text-sm font-medium hover:text-indigo-700 transition-colors"
                    >
                      Preview
                    </button>
                    <label className="text-indigo-600 text-sm font-medium hover:text-indigo-700 transition-colors cursor-pointer">
                      Replace
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                        accept=".pdf,.doc,.docx"
                      />
                    </label>
                  </div>
                </div>
              ) : (
                <label className="flex flex-col items-center cursor-pointer w-full space-y-5">
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx"
                  />
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-purple-400 rounded-full blur-lg opacity-0 group-hover:opacity-20 transition-opacity" />
                    <div className="p-3 bg-indigo-100 rounded-2xl transition-transform group-hover:scale-110 group-hover:-translate-y-2 shadow-inner">
                      <BsCloudArrowUp className="h-8 w-8 text-indigo-600" />
                    </div>
                  </div>
                  <div className="text-center space-y-1">
                    <p className="text-gray-600 font-medium text-sm">
                      Drag & drop file or <span className="text-indigo-600">browse</span>
                    </p>
                    <p className="text-gray-400 text-xs">
                      PDF (Max 5MB)
                    </p>
                  </div>
                </label>
              )}
            </div>

            {error && (
              <div className="mt-4 text-red-500 text-sm text-center flex items-center justify-center space-x-2">
                <span>{error}</span>
                {errorDetails && (
                  <button
                    onClick={() => setShowErrorModal(true)}
                    className="text-indigo-600 hover:text-indigo-700 underline text-sm"
                  >
                    View Details
                  </button>
                )}
              </div>
            )}

            {!(processing || processingComplete) && (
              <button
                onClick={handleNext}
                disabled={!cv || buttonLoading}
                className={`mt-8 w-full py-4 rounded-xl font-semibold text-white transition-all relative flex items-center justify-center ${cv && !buttonLoading ? "hover:shadow-lg" : "opacity-80 cursor-not-allowed"}`}
                style={{
                  background: cv
                    ? "linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%)"
                    : "#6159e8",
                }}
              >
                {buttonLoading ? (
                  <div className="w-5 h-5 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                ) : (
                  <span className="relative z-10">Continue to Interview</span>
                )}
                {cv && !buttonLoading && (
                  <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/0 to-purple-600/20 opacity-0 hover:opacity-100 transition-opacity" />
                )}
              </button>
            )}
          </div>
        </div>

        {/* Document Preview Modal */}
        {showPreview && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 h-screen">
            <div className="bg-white rounded-xl shadow-2xl max-w-2xl w-full max-h-[90vh] flex flex-col">
              <div className="flex justify-between items-center px-6 py-4 border-b">
                <h3 className="text-lg font-semibold">Document Preview</h3>
                <button
                  onClick={() => setShowPreview(false)}
                  className="text-gray-500 hover:text-gray-700 p-2 rounded-lg"
                >
                  ✕
                </button>
              </div>
              <div className="flex-1 overflow-auto p-4">
                <object
                  data={previewUrl}
                  type="application/pdf"
                  width="100%"
                  className="rounded-lg border h-64 md:h-[500px]"
                >
                  <div className="text-center p-4 space-y-2">
                    <p className="text-gray-600">
                      Preview not available. Download the file to view.
                    </p>
                    <a
                      href={previewUrl}
                      download={cv?.name}
                      className="inline-flex items-center text-indigo-600 hover:text-indigo-700 font-medium"
                    >
                      Download File
                    </a>
                  </div>
                </object>
              </div>
            </div>
          </div>
        )}

        {/* Processing Modal */}
        {(processing || processingComplete) && (
          <div className="min-h-screen fixed inset-0 bg-black/70 backdrop-blur-lg z-50 flex items-center justify-center p-4">
            <div
              className="bg-white rounded-3xl shadow-2xl max-w-lg w-full p-10 space-y-6 transition-all duration-300"
              role="dialog"
              aria-live="polite"
            >
              {!processingComplete ? (
                <div className="flex flex-col items-center">
                  <div className="relative mb-8">
                    <div className="w-24 h-24 border-4 border-slate-100 rounded-full" />
                    <div className="w-24 h-24 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin absolute inset-0" />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <svg className="w-10 h-10 text-indigo-500 animate-pulse" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                        <polyline points="14 2 14 8 20 8" />
                        <line x1="16" y1="13" x2="8" y2="13" />
                        <line x1="16" y1="17" x2="8" y2="17" />
                        <line x1="10" y1="9" x2="8" y2="9" />
                      </svg>
                    </div>
                  </div>
                  <h3 className="text-2xl font-bold text-slate-800 mb-4">
                    Analyzing Your CV
                  </h3>
                  <p className="text-slate-600 text-center mb-6 text-md">
                    We're carefully reviewing your qualifications and experience to find the perfect opportunities.
                  </p>

                  {showAlmostThere && (
                    <div className="mt-2 p-4 bg-indigo-50 rounded-lg text-indigo-700 border border-indigo-100 flex items-center">
                      <svg className="w-5 h-5 mr-2 text-indigo-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="12" />
                        <line x1="12" y1="16" x2="12.01" y2="16" />
                      </svg>
                      Almost there! Just a few more moments...
                    </div>
                  )}
                  {!showAlmostThere && (
                    <div className="bg-indigo-100 rounded-full p-2 w-fit text-center mt-6 text-sm text-indigo-700 px-6">
                      This process may take up to one minute to complete.
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="w-20 h-20 bg-indigo-100 rounded-full flex items-center justify-center mb-8">
                    <svg
                      className="w-10 h-10 text-indigo-600"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-bold text-slate-800 mb-4">
                    Analysis Complete!
                  </h3>
                  <p className="text-slate-600 text-center mb-8 text-md">
                    Your CV has been successfully processed. We've identified your key strengths and potential matches.
                  </p>
                  <button
                    onClick={() => navigate("/signup/onboarding-step2")}
                    className="w-full py-4 px-6 rounded-xl font-semibold text-white bg-gradient-to-r from-indigo-600 to-indigo-600 hover:from-indigo-700 hover:to-indigo-700 transition-all duration-200 transform hover:-translate-y-1 shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Continue to Interview
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {/* Error Details Modal */}
        {showErrorModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl shadow-2xl max-w-md w-full p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold text-gray-900">Error Details</h3>
                <button
                  onClick={() => setShowErrorModal(false)}
                  className="text-gray-500 hover:text-gray-700 p-2 rounded-lg"
                >
                  ✕
                </button>
              </div>
              <div className="text-gray-600 text-sm overflow-auto max-h-64">
                {errorDetails}
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowErrorModal(false)}
                  className="py-2 px-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default OnboardingStep1;