import { BlockBlobClient } from "@azure/storage-blob";

// Utility: delay for a given number of milliseconds.
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Utility: estimate remaining upload time (in seconds).
function estimateTimeRemaining(startTime, uploadedBytes, totalBytes) {
  const elapsed = (performance.now() - startTime) / 1000; // seconds
  const uploadRate = uploadedBytes / elapsed; // bytes per second
  const remainingBytes = totalBytes - uploadedBytes;
  const estimatedSeconds = uploadRate > 0 ? remainingBytes / uploadRate : 0;
  return Math.round(estimatedSeconds);
}

/**
 * Uploads a video file to Azure Blob Storage using chunked upload with retry logic.
 *
 * @param {File} file - The video file to upload.
 * @param {function} onProgress - Callback receiving progress updates:
 *                                { percent, uploadedBytes, totalBytes, estimatedTimeRemaining }
 * @param {AbortSignal} abortSignal - Optional signal to cancel the upload.
 * @returns {Promise<object>} - The JSON response from the processing endpoint.
 * @throws {Error} - Throws an error if any step fails.
 */
export async function uploadPromoVideo(file, onProgress, abortSignal) {
  try {
    // Step 1: Request a SAS URL from your backend.
    const getUrlResponse = await fetch("https://jobseeker.asendia.ai/api/get-upload-url", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({ filename: file.name }),
    });

    if (!getUrlResponse.ok) {
      throw new Error("Failed to get upload URL");
    }

    const { upload_url, blob_url } = await getUrlResponse.json();

    // Step 2: Create a BlockBlobClient using the SAS URL.
    const blockBlobClient = new BlockBlobClient(upload_url);

    // Set chunk upload parameters.
    const chunkSize = 4 * 1024 * 1024; // 4MB per chunk.
    const totalChunks = Math.ceil(file.size / chunkSize);
    let blockIds = [];
    let uploadedBytes = 0;
    const startTime = performance.now();

    // Loop through each chunk.
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);
      // Create a block ID (must be base64-encoded).
      const blockId = btoa(String(i).padStart(6, "0"));
      blockIds.push(blockId);

      let retries = 0;
      const maxRetries = 3;
      let success = false;
      while (!success) {
        try {
          await blockBlobClient.stageBlock(blockId, chunk, chunk.size, { abortSignal });
          success = true;
          uploadedBytes += chunk.size;
          // Report progress.
          if (onProgress && file.size > 0) {
            onProgress({
              percent: Math.round((uploadedBytes / file.size) * 100),
              uploadedBytes,
              totalBytes: file.size,
              estimatedTimeRemaining: estimateTimeRemaining(startTime, uploadedBytes, file.size),
            });
          }
        } catch (err) {
          retries++;
          if (retries > maxRetries) {
            throw new Error(
              `Chunk ${i + 1} failed after ${maxRetries} retries: ${err.message}`
            );
          }
          // Wait before retrying (exponential backoff).
          await delay(1000 * Math.pow(2, retries));
        }
      }
    }

    // Commit all uploaded chunks.
    await blockBlobClient.commitBlockList(blockIds, {
      blobHTTPHeaders: { blobContentType: file.type },
      abortSignal,
    });

    // Step 3: Trigger video processing (optional).
    try {
      const processResponse = await fetch("https://jobseeker.asendia.ai/api/process-video", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ video_url: blob_url }),
      });

      if (!processResponse.ok) {
        console.warn("Triggering processing failed, but upload is successful.");
        return {
          message:
            "Upload successful, but processing failed. You can retry processing later.",
        };
      }

      return await processResponse.json();
    } catch (processError) {
      console.warn("Processing trigger error:", processError);
      return {
        message: "Upload successful, but processing failed. You can retry processing later.",
      };
    }
  } catch (error) {
    console.error("Error during video upload:", error);
    throw new Error("Video upload failed. Please try again.");
  }
}
