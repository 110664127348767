// TourComponent.js
import React from 'react';
import Tour from 'reactour';
import './TourComponent.css'; // Create this CSS file

const accentColor = '#7C3AED'; // More modern purple shade

const tourConfig = [
    {
        selector: '[data-tut="reactour__dashboard"]',
        content: () => (
            <div className="tour-content">
                <h3>🧭 Navigation</h3>
                <p>You can find all necessary navigation items here to explore different sections.</p>
            </div>
        ),
        position: 'right',
    },    
    {
        selector: '[data-tut="reactour__dashboard"]',
        content: () => (
            <div className="tour-content">
                <h3>📊 Dashboard</h3>
                <p>This is your dashboard, where you track progress and updates.</p>
            </div>
        ),
        position: 'right',
    },
    {
        selector: '[data-tut="reactour__profile"]',
        content: () => (
            <div className="tour-content">
                <h3>👤 Profile</h3>
                <p>Here you can manage your profile details and update your information.</p>
            </div>
        ),
        position: 'right',
    },
    {
        selector: '[data-tut="reactour__buddies"]',
        content: () => (
            <div className="tour-content">
                <h3>🤝 Buddies</h3>
                <p>Check out and connect with your buddies in this section.</p>
            </div>
        ),
        position: 'right',
    },
    {
        selector: '[data-tut="reactour__report"]',
        content: () => (
            <div className="tour-content">
                <h3>📑 Reports</h3>
                <p>View your assessment reports and track your progress over time.</p>
            </div>
        ),
        position: 'right',
    },
    {
        selector: '[data-tut="reactour__referrals"]',
        content: () => (
            <div className="tour-content">
                <h3>🎁 Referrals</h3>
                <p>Invite friends and earn rewards through referrals.</p>
            </div>
        ),
        position: 'right',
    },
    {
        selector: '[data-tut="reactour__billing"]',
        content: () => (
            <div className="tour-content">
                <h3>💳 Billing</h3>
                <p>Manage your billing details and subscription plans here.</p>
            </div>
        ),
        position: 'right',
    }
];


const TourComponent = ({ closeTour, disableBody, enableBody, setCurrentStep, isTourOpen }) => {
    
    return (
        <Tour
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={isTourOpen}
            maskClassName="mask"
            className="tour-helper"
            rounded={10}
            accentColor={accentColor}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            getCurrentStep={setCurrentStep}
            disableDotsNavigation={true}
            closeWithMask={false}
            badgeContent={(current) => `${current}/${tourConfig.length}`}
            showNavigationNumber={true}
            showNavigation={false}
            showButtons={true}
            showCloseButton={true}
            scrollDuration={500}
        />
    );
};

export default TourComponent;
