import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBuddies } from "../../Services/ApiEnpoints";

// ✅ Async thunk to fetch buddy list from API
export const fetchBuddies = createAsyncThunk(
  "buddy/fetchBuddies",
  async (userData, { rejectWithValue }) => {
    try {
      if (!userData?.id || !userData?.email) throw new Error("User data missing");
      const response = await getAllBuddies(userData.id, userData.email);
      return response; // ✅ Returns buddy list to Redux store
    } catch (error) {
      return rejectWithValue("Failed to fetch buddies.");
    }
  }
);

const initialState = {
  isAgentFormVisible: false,
  buddydetails: null,
  AiAgentoption: false,
  buddyList: [],
  activeBuddy: null,
  aitype: "ai",
  expandedSections: {
    ai: false,
    coffee: false,
  },
  videoCall: false,
  audioCall: false,
  activedetails: [],
  isDarkMode: localStorage.getItem("theme") === "dark",
  loading: false, // ✅ Added loading state for API calls
  error: null, // ✅ Track API errors
};

const buddySlice = createSlice({
  name: "buddy",
  initialState,
  reducers: {
    setIsAgentFormVisible(state, action) {
      state.isAgentFormVisible = action.payload;
    },
    setBuddydetails(state, action) {
      state.buddydetails = action.payload;
    },
    setAiAgentoption(state, action) {
      state.AiAgentoption = action.payload;
    },
    setBuddyList(state, action) {
      state.buddyList = action.payload;
    },
    setActiveBuddy(state, action) {
      state.activeBuddy = action.payload;
    },
    setAitype(state, action) {
      state.aitype = action.payload;
    },
    setExpandedSections(state, action) {
      state.expandedSections = action.payload;
    },
    setVideoCall(state, action) {
      state.videoCall = action.payload;
    },
    setAudioCall(state, action) {
      state.audioCall = action.payload;
    },
    setActivedetails(state, action) {
      state.activedetails = action.payload;
    },
    toggleDarkMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem("theme", state.isDarkMode ? "dark" : "light");
      document.documentElement.classList.toggle("dark", state.isDarkMode);
    },
    setDarkMode(state, action) {
      state.isDarkMode = action.payload;
    },
    resetState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuddies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuddies.fulfilled, (state, action) => {
        state.buddyList = action.payload;
        state.loading = false;
      })
      .addCase(fetchBuddies.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const {
  setIsAgentFormVisible,
  setBuddydetails,
  setAiAgentoption,
  setBuddyList,
  setActiveBuddy,
  setAitype,
  setExpandedSections,
  setVideoCall,
  setAudioCall,
  setActivedetails,
  toggleDarkMode,
  setDarkMode,
  resetState,
} = buddySlice.actions;

export default buddySlice.reducer;
