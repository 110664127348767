// AuthenticatedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const AuthenticatedRoute = () => {
  const user = useSelector((state) => state.user);
  // You may check for a specific flag (e.g., user.isAuthenticated) if available
  return user ? <Outlet /> : <Navigate to="/signin" replace />;
};

export default AuthenticatedRoute;
