import React, { useEffect, useRef, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { MdCall } from "react-icons/md";
import { RetellWebClient } from "retell-client-js-sdk";
import { BlockBlobClient } from "@azure/storage-blob";
import { useDispatch } from "react-redux";
import { settoastDetails } from "../../../../../Redux/Slices/toastSlice";
import faanglogoName from "../../../../../assests/Images/asendia_logo_text_white.png";
import asendia_logo from "../../../../../assests/Images/asendia_logo.png";
import { BsExclamationCircle } from "react-icons/bs";
import { IoReload } from "react-icons/io5";
import { fetchProfileData } from "../../../../../Redux/Slices/profileSlice";
import RecordRTC from "recordrtc"; // Polyfill for MediaRecorder
import { logDebugToBackend, saveVideoToAzure, sendErrorToBackend } from "../../../../../Services/ApiEnpoints";

// Loading & Error Overlay Component
const LoadingOverlay = ({ error, message, actionLabel, onAction }) => (
  <div className="fixed inset-0 bg-[#131313]/90 backdrop-blur-sm flex flex-col items-center justify-center z-50">
    <div className="text-center text-white flex flex-col items-center justify-center space-y-4">
      {error ? (
        <>
          <div className="flex flex-col items-center space-y-4 px-4">
            <div className="p-3 bg-red-500/10 rounded-full">
              <BsExclamationCircle className="w-10 h-10 text-red-400" strokeWidth={1.5} />
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-medium text-red-100">{error.message}</h3>
              <p className="text-gray-300/90 max-w-md">{error.details}</p>
            </div>
          </div>
          {onAction && (
            <button
              onClick={onAction}
              className="mt-2 px-5 py-2.5 bg-white/5 hover:bg-white/10 border border-white/10 rounded-lg backdrop-blur-sm transition-all duration-300 flex items-center gap-2"
            >
              <IoReload className="w-5 h-5 text-white animate-spin" />
              <span className="font-medium">{actionLabel || "Retry"}</span>
            </button>
          )}
        </>
      ) : (
        <>
          <div className="relative flex items-center justify-center">
            <div className="absolute w-16 h-16 border-2 border-white/10 rounded-full"></div>
            <div className="h-12 w-12 border-4 border-transparent border-t-blue-400 border-r-blue-400 rounded-full animate-spin"></div>
          </div>
          <div className="space-y-2">
            <h3 className="text-xl font-light tracking-wide text-gray-200 animate-pulse">
              {message || "Processing Request"}
            </h3>
            <p className="text-sm text-gray-400/90 font-light">This may take a few moments.</p>
          </div>
        </>
      )}
    </div>
  </div>
);

// Media Error Screen Component
const MediaErrorScreen = ({ errorMessage, onRetry }) => (
  <div className="flex flex-col items-center justify-center h-screen bg-gray-900">
    <div className="text-white text-xl mb-4">{errorMessage}</div>
    <button onClick={onRetry} className="bg-indigo-600 px-4 py-2 rounded text-white">
      Retry
    </button>
  </div>
);

// Main Interview Component
const TalenthubVideoInterview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { interviewResponse } = location.state || {};
  const access_token = interviewResponse?.access_token;
  const user_id = interviewResponse?.user_id;
  const call_id = interviewResponse?.call_id;
  const profileId = interviewResponse?.profile_id || "default_profile";
  const { previousRoute } = location.state || {};
  const startTimeRef = useRef(null);

  const MINIMUM_INTERVIEW_DURATION = 420; //  7 minutes in seconds
  const PERIODIC_COMMIT_INTERVAL = 300000; // 7 minutes in milliseconds
  const [elapsedTime, setElapsedTime] = useState(0);
  const [mediaError, setMediaError] = useState(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [mediaReady, setMediaReady] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");
  const [showEndCallModal, setShowEndCallModal] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [interviewSubmitted, setInterviewSubmitted] = useState(true);
  const [isAgentTalking, setIsAgentTalking] = useState(false);
  const [userNotification, setUserNotification] = useState("");
  const mimeTypeRef = useRef("video/webm"); // Default MIME type
  const commitIntervalRef = useRef(null); // To manage periodic commit interval
  const isLowEndDevice = useMemo(() => {
    const cores = navigator.hardwareConcurrency || 2;
    const deviceMemory = navigator.deviceMemory || 2;
    const screenWidth = window.screen.width;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    return cores < 4 || deviceMemory < 2 || screenWidth < 768 || isMobile;
  }, []);


  const chunkDataRef = useRef([]); // Store raw chunk data for fallback



  const fallbackToMicroservice = async (chunks, filename, mimeType, attempt = 1) => {
    const maxAttempts = 1;
    try {
      const response = await saveVideoToAzure(chunks, filename, mimeType, user_id, profileId, call_id);
      logDebugToBackend("Fallback save succeeded", "upload", { chunkCount: chunks.length });
      setUploadComplete(true);
      setInterviewSubmitted(true);
      setProcessingMessage("");
      setUploadError(null);
    } catch (err) {
      console.error(`Fallback save attempt ${attempt} failed:`, err);
      sendErrorToBackend(err, { stage: "fallbackSave", attempt });

      if (attempt < maxAttempts) {
        logDebugToBackend("Retrying fallback save", "upload", { attempt });
        await sleep(attempt * 2000);
        return fallbackToMicroservice(chunks, filename, mimeType, attempt + 1);
      }

      setProcessingMessage("");
      setUploadComplete(true);
      setInterviewSubmitted(true);
    }
  };


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && mediaRecorderRef.current?.state === "recording") {
        mediaRecorderRef.current.requestData();
        setUserNotification("Warning: Your interview session is being recorded. Please do not switch or hide this browser tab.");
        setTimeout(() => setUserNotification(""), 5000);
      }
    };
    const handleOffline = () => {
      setUserNotification("Warning: Your network connection is offline. Please check your connection to ensure your video is saved.");
    };
    const handleOnline = () => {
      setUserNotification("Info: Network connection restored. Resuming normal operations.");
      setTimeout(() => setUserNotification(""), 5000);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const videoRef = useRef(null);
  const retellWebClientRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const blockBlobClientRef = useRef(null);
  const blockIdsRef = useRef([]);
  const filenameRef = useRef(null);
  const micStreamRef = useRef(null);
  const finalDurationRef = useRef(0);

  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
  const destinationRef = useRef(null);
  const retellGainRef = useRef(null);
  const retellFilterRef = useRef(null);
  const retellCompressorRef = useRef(null);
  const micGainRef = useRef(null);
  const retellLowpassRef = useRef(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    useEffect(() => {
    if (!destinationRef.current) {
      // Create a single audio destination
      destinationRef.current = audioContextRef.current.createMediaStreamDestination();

      // Simple gain for retell
      retellGainRef.current = audioContextRef.current.createGain();
      retellGainRef.current.gain.value = 1.0; // Keep voice natural

      // Connect retell gain to final destination
      retellGainRef.current.connect(destinationRef.current);

      // Mic gain
      micGainRef.current = audioContextRef.current.createGain();
      micGainRef.current.gain.value = 1.0;
      micGainRef.current.connect(destinationRef.current);
    }
  }, []);

  useEffect(() => {
    const context = audioContextRef.current;
    const handleStateChange = () => {
      console.log("AudioContext state:", context.state);
    };
    context.onstatechange = handleStateChange;
    return () => {
      context.onstatechange = null;
      context.close().catch((err) => console.warn("Error closing AudioContext:", err));
    };
  }, []);

  const stateRef = useRef();
  useEffect(() => {
    stateRef.current = {
      isCallActive,
      uploadComplete,
      blockIds: blockIdsRef.current,
      blockBlobClient: blockBlobClientRef.current,
    };
  }, [isCallActive, uploadComplete]);

  const elapsedTimeRef = useRef(0);
  useEffect(() => {
    elapsedTimeRef.current = elapsedTime;
  }, [elapsedTime]);

  const stopAllMedia = () => {
    try {
      if (mediaRecorderRef.current?.state === "recording") {
        mediaRecorderRef.current.requestData();
        setTimeout(() => {
          mediaRecorderRef.current.stop();
          audioContextRef.current?.close();
        }, 500); // Wait for final chunk
      }
      retellWebClientRef.current?.stopCall();
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
    } catch (error) {
      console.error("Error stopping media:", error);
      sendErrorToBackend(error, { stage: "stopAllMedia" });
    }
  };

  useEffect(() => {
    let interval;
    if (isCallActive) {
      interval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCallActive]);

  useEffect(() => {
    retellWebClientRef.current = new RetellWebClient();
    return () => {
      retellWebClientRef.current?.stopCall();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (stateRef.current.isCallActive && !stateRef.current.uploadComplete) {
        event.preventDefault();
        event.returnValue = "";
        stopAllMedia();
        if (blockIdsRef.current.length > 0 && elapsedTime >= MINIMUM_INTERVIEW_DURATION) {
          const payload = JSON.stringify({
            blockIds: blockIdsRef.current,
            filename: filenameRef.current,
            profile_id: profileId,
          });
          navigator.sendBeacon("https://jobseeker.asendia.ai/api/finalize-upload", payload);
        }
      }
    };
    const handlePopState = () => {
      if (stateRef.current.isCallActive && !stateRef.current.uploadComplete) {
        stopAllMedia();
        navigate(location.pathname, { replace: true });
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, location.pathname, profileId]);

  const requestMediaAccess = async () => {
    try {
      const constraints = isLowEndDevice
        ? {
          video: { width: { ideal: 640 }, height: { ideal: 360 }, frameRate: { ideal: 24, max: 24 }, facingMode: "user" },
          audio: true,
        }
        : {
          video: { width: { ideal: 1024 }, height: { ideal: 576 }, frameRate: { ideal: 24, max: 24 }, facingMode: "user", aspectRatio: 16 / 9 },
          audio: true,
        };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (audioContextRef.current.state === "suspended") {
        await audioContextRef.current.resume();
      }
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.oncanplay = () => {
          videoRef.current
            .play()
            .then(() => setMediaReady(true))
            .catch((err) => {
              console.error("Video play error:", err);
              sendErrorToBackend(err, { stage: "videoPlay" });
            });
        };
      }
      micStreamRef.current = stream;
      if (stream && audioContextRef.current && micGainRef.current) {
        const micAudioSource = audioContextRef.current.createMediaStreamSource(stream);
        micAudioSource.connect(micGainRef.current);
      }
    } catch (error) {
      console.error("Error accessing media:", error);
      sendErrorToBackend(error, { stage: "requestMediaAccess" });
      setMediaError("Unable to access camera and microphone. Please check permissions and try again.");
    }
  };

  useEffect(() => {
    if (!access_token) return;
    requestMediaAccess();
  }, [access_token]);

  const getSASToken = async (filename) => {
    try {
      const response = await fetch("https://jobseeker.asendia.ai/api/get-sas-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ user_id, profile_id: profileId, filename }),
      });
      if (!response.ok) throw new Error("Failed to get SAS token");
      return await response.json();
    } catch (err) {
      console.error("Error getting SAS token:", err);
      sendErrorToBackend(err, { stage: "getSASToken" });
      dispatch(settoastDetails({
        uniqueId: "",
        toaststate: true,
        message: { title: "Error", description: "Failed to get SAS token. Please try again." },
        icon: "error",
      }));
      throw err;
    }
  };

  const triggerProcessing = async (filename) => {
    try {
      const response = await fetch("https://jobseeker.asendia.ai/api/trigger-processing", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id, profile_id: profileId, filename, call_id }),
      });
      if (!response.ok) throw new Error("Failed to trigger processing");
    } catch (err) {
      console.error("Error triggering processing:", err);
      sendErrorToBackend(err, { stage: "triggerProcessing", filename });
    }
  };

  const stageBlockWithRetry = async (blockId, data, size, attempt = 1) => {
    const maxAttempts = 5;
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    try {
      await blockBlobClientRef.current.stageBlock(blockId, data, size);
    } catch (err) {
      if (attempt < maxAttempts) {
        await delay(attempt * 1000);
        return stageBlockWithRetry(blockId, data, size, attempt + 1);
      } else {
        console.error("Stage block failed after retries:", err);
        throw err;
      }
    }
  };

  const commitBlockListWithRetry = async (blockIds, mimeType, attempt = 1) => {
    const maxAttempts = 10;
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    try {
      await blockBlobClientRef.current.commitBlockList(blockIds, {
        blobHTTPHeaders: { blobContentType: mimeType },
      });
    } catch (err) {
      if (attempt < maxAttempts) {
        await delay(attempt * 1000);
        return commitBlockListWithRetry(blockIds, mimeType, attempt + 1);
      } else {
        console.error("Commit block list failed after retries:", err);
        sendErrorToBackend(err, { stage: "commitBlockList" });
        throw err;
      }
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const startRecording = async () => {
    try {
      const stream = videoRef.current.srcObject;
      const audioTracks = destinationRef.current.stream.getAudioTracks();
      const finalStream = new MediaStream([...stream.getVideoTracks(), ...audioTracks]);

      if (typeof MediaRecorder === "undefined") {
        mediaRecorderRef.current = new RecordRTC(finalStream, { type: "video", mimeType: mimeTypeRef.current });
        mediaRecorderRef.current.startRecording();
        logDebugToBackend("Using RecordRTC polyfill", "recording");
      } else {
        if (MediaRecorder.isTypeSupported("video/webm")) {
          mimeTypeRef.current = "video/webm";
        } else if (MediaRecorder.isTypeSupported("video/mp4")) {
          mimeTypeRef.current = "video/mp4";
        } else {
          setMediaError("Your browser does not support video recording in a compatible format.");
          return;
        }

        const extension = mimeTypeRef.current === "video/webm" ? "webm" : "mp4";
        const filename = `video_${Date.now()}_withvoice.${extension}`;
        filenameRef.current = filename;

        logDebugToBackend("Starting recording", "recording", {
          user_id,
          call_id,
          profileId,
          mimeType: mimeTypeRef.current,
          userAgent: navigator.userAgent,
          isLowEnd: isLowEndDevice,
        });

        setProcessingMessage("Initializing Interview...");
        const { sas_url } = await getSASToken(filename);
        blockBlobClientRef.current = new BlockBlobClient(sas_url);
        blockIdsRef.current = [];
        chunkDataRef.current = [];

        const mediaRecorder = new MediaRecorder(finalStream, { mimeType: mimeTypeRef.current });
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = async (event) => {
          if (event.data && event.data.size > 0) {
            // logDebugToBackend(`Chunk ${blockIdsRef.current.length + 1} received`, "recording", {
            //   chunkSize: event.data.size,
            // });
            const blockId = btoa(`block-${crypto.randomUUID()}`);
            try {
              await stageBlockWithRetry(blockId, event.data, event.data.size);
              blockIdsRef.current.push(blockId);
              chunkDataRef.current.push({ blockId, data: event.data });
            } catch (err) {
              logDebugToBackend("Chunk upload failed after retries", "upload", {
                blockId,
                error: err.message,
              });
              chunkDataRef.current.push({ blockId, data: event.data });
            }
          } else {
            logDebugToBackend("Zero-byte chunk received", "recording");
          }
        };

        mediaRecorder.onerror = (error) => {
          console.error("MediaRecorder error:", error);
          sendErrorToBackend(error, { stage: "MediaRecorder" });
        };

        mediaRecorder.start(4000); // 5-second chunks
        logDebugToBackend("Recording started", "recording");


        // Set up periodic commit every 2 minutes
        commitIntervalRef.current = setInterval(async () => {
          if (mediaRecorderRef.current?.state === "recording") {
            const currentBlockIds = [...blockIdsRef.current];
            if (currentBlockIds.length > 0) {
              try {
                await commitBlockListWithRetry(currentBlockIds, mimeTypeRef.current);
                logDebugToBackend("Periodic commit succeeded", "upload", { blockCount: currentBlockIds.length });
              } catch (err) {
                console.error("Periodic commit failed:", err);
                sendErrorToBackend(err, { stage: "periodicCommit" });
              }
            }
          }
        }, PERIODIC_COMMIT_INTERVAL);

        mediaRecorder.onstop = async () => {
          setProcessingMessage("Finalizing upload...");
          clearInterval(commitIntervalRef.current); // Stop periodic commits
          await sleep(1500); // Allow time for final ondataavailable
          const finalDuration = finalDurationRef.current || elapsedTime;

          logDebugToBackend("Recording stopped", "recording", {
            duration: finalDuration,
            chunkCount: blockIdsRef.current.length,
            network: navigator.onLine ? "Online" : "Offline",
          });

          if (finalDuration < MINIMUM_INTERVIEW_DURATION) {
            logDebugToBackend("Interview too short, not saving", "recording");
            setProcessingMessage("");
            setUploadComplete(true);
            setInterviewSubmitted(false);
            return;
          }

          const expectedChunks = Math.ceil(finalDuration / 5);
          if (blockIdsRef.current.length < expectedChunks * 0.9) {
            logDebugToBackend("Too many chunks missing, triggering fallback", "upload", {
              expected: expectedChunks,
              actual: blockIdsRef.current.length,
            });
            await fallbackToMicroservice(chunkDataRef.current, filename, mimeTypeRef.current);
            return;
          }

          if (!navigator.onLine) {
            logDebugToBackend("Network offline, triggering fallback", "upload");
            await fallbackToMicroservice(chunkDataRef.current, filename, mimeTypeRef.current);
            return;
          }

          setProcessingMessage("Finalizing upload...");
          try {
            await commitBlockListWithRetry(blockIdsRef.current, mimeTypeRef.current);
            logDebugToBackend("Commit succeeded", "upload");
            setUploadComplete(true);
            setInterviewSubmitted(true);
            chunkDataRef.current = []; // Clear after success
          } catch (err) {
            console.error("Commit failed:", err);
            sendErrorToBackend(err, { stage: "finalCommit" });
            logDebugToBackend("Commit failed, triggering fallback", "upload");
            await fallbackToMicroservice(chunkDataRef.current, filename, mimeTypeRef.current);
            return;
          }
          try {
            logDebugToBackend("Triggering processing", "processing", { filename });
            await triggerProcessing(filenameRef.current);
            logDebugToBackend("Processing triggered successfully", "processing");
          } catch (err) {
            console.error("Processing failed:", err);
            sendErrorToBackend(err, { stage: "triggerProcessing" });
          }
          await sleep(2000);
          setProcessingMessage("");
        };
      }
    } catch (error) {
      console.error("Error starting recording:", error);
      sendErrorToBackend(error, { stage: "startRecording" });
      setProcessingMessage("Error initializing recording.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current?.state === "recording") {
      mediaRecorderRef.current.requestData();
      setTimeout(() => {
        mediaRecorderRef.current.stop();
      }, 500); // Wait for final chunk
    }
  };

  useEffect(() => {
    if (!mediaReady || !access_token) return;
    const client = retellWebClientRef.current;

    startRecording()
      .then(() => {
        return client.startCall({
          accessToken: access_token,
          sampleRate: 48000,
          captureDeviceId: "default",
          playbackDeviceId: "default",
          emitRawAudioSamples: true,
        });
      })
      .then(() => {
        startTimeRef.current = Date.now(); // Capture call start time
        setProcessingMessage("");
        setIsCallActive(true);
      })
      .catch((error) => {
        console.error("Error starting recording or connecting the call:", error);
        sendErrorToBackend(error, { stage: "startCall" });
      });


    client.on("call_ended", () => {
      const endTime = Date.now();

      console.log("Call ended by Retell. Start Time:", startTimeRef.current, "End Time:", endTime);

      // Calculate the duration in seconds
      finalDurationRef.current = startTimeRef.current
        ? Math.floor((endTime - startTimeRef.current) / 1000)
        : 0;

      console.log("Computed Duration:", finalDurationRef.current, "Elapsed Time:", elapsedTime, "Elapsed Time Ref:", elapsedTimeRef.current);

      setIsCallActive(false);
      setCallEnded(true);
      stopRecording();

      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }

      if (finalDurationRef.current <= 0) {
        console.error("Error: Final duration is 0 despite interview running. Investigate!");
      }
    });



    client.on("agent_start_talking", () => {
      setIsAgentTalking(true);
    });

    client.on("agent_stop_talking", () => {
      setIsAgentTalking(false);
    });

    client.on("audio", (rawAudio) => {
      try {
        if (audioContextRef.current.state === "suspended") {
          audioContextRef.current.resume();
        }
        const sampleRate = 48000;
        const audioBuffer = audioContextRef.current.createBuffer(1, rawAudio.length, sampleRate);
        audioBuffer.copyToChannel(rawAudio, 0);
        const bufferSource = audioContextRef.current.createBufferSource();
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(retellGainRef.current);
        bufferSource.start();
        bufferSource.onended = () => {
          bufferSource.disconnect();
        };
      } catch (err) {
        console.error("Error processing retell audio chunk:", err);
        sendErrorToBackend(err, { stage: "audioProcessing" });
      }
    });

    client.on("update", (update) => {
      console.log(update.transcript);
    });

    client.on("error", (error) => {
      console.error("An error occurred:", error);
      sendErrorToBackend(error, { stage: "clientError" });
      dispatch(settoastDetails({
        uniqueId: "",
        toaststate: true,
        message: { title: "Error", description: "An error occurred during the call. Please try again." },
        icon: "error",
      }));
      if (elapsedTimeRef.current >= MINIMUM_INTERVIEW_DURATION) {
        stopRecording();
      } else {
        client.stopCall();
      }
    });

    return () => {
      client.off("call_ended");
      client.off("agent_start_talking");
      client.off("agent_stop_talking");
      client.off("update");
      client.off("error");
      client.off("audio");
    };
  }, [mediaReady, access_token, dispatch]);

  const toggleMute = () => {
    if (!retellWebClientRef.current) return;
    if (isMicMuted) {
      retellWebClientRef.current.unmute();
    } else {
      retellWebClientRef.current.mute();
    }
    setIsMicMuted(!isMicMuted);
  };

  const handleEndCallClick = () => {
    setShowEndCallModal(true);
  };

  const confirmEndCall = () => {
    finalDurationRef.current = elapsedTime;
    setShowEndCallModal(false);
    retellWebClientRef.current?.stopCall();
  };

  const cancelEndCall = () => {
    setShowEndCallModal(false);
  };

  const handleBackNavigation = () => {
    stopAllMedia();
    if (previousRoute === "/signup/onboarding-step2") {
      navigate("/signup/onboarding-step3");
    } else {
      const shouldPassState =
        previousRoute === "/signup/onboarding-step3" ||
        (previousRoute && previousRoute.includes("onboarding-step3"));
      navigate("/dashboard", { state: shouldPassState ? { userRegistered: true } : {} });
      dispatch(fetchProfileData());
    }
  };

  const retryUpload = async () => {
    setProcessingMessage("Retrying upload...");
    setUploadError(null);
    try {
      await commitBlockListWithRetry(blockIdsRef.current, mimeTypeRef.current);
      logDebugToBackend("Retry commit succeeded", "upload");
      await triggerProcessing(filenameRef.current);
      await sleep(2000);
      setProcessingMessage("");
      setUploadComplete(true);
      setInterviewSubmitted(true);
      setUploadError(null);
    } catch (err) {
      console.error("Retry upload failed:", err);
      setProcessingMessage("");
      setUploadComplete(true);
      setInterviewSubmitted(true);
      setUploadError(null);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  if (!access_token && !uploadComplete) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center p-8">
          <h2 className="text-xl font-bold mb-4 text-red-600">Session Error</h2>
          <p className="text-gray-600">Please restart the interview from the beginning.</p>
        </div>
      </div>
    );
  }

  if (mediaError) {
    return <MediaErrorScreen errorMessage={mediaError} onRetry={requestMediaAccess} />;
  }

  return (
    <>
      {userNotification && (
        <div className="fixed top-0 w-full bg-yellow-600 text-white text-center p-2 z-50">
          {userNotification}
        </div>
      )}

      {processingMessage && <LoadingOverlay message={processingMessage} />}
      {uploadError && (
        <LoadingOverlay error={uploadError} actionLabel="Retry Upload" onAction={retryUpload} />
      )}
      {callEnded && uploadComplete && !uploadError && (
        <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-50">
          <div className="relative w-full max-w-lg p-10 bg-white/90 border border-white/20 shadow-2xl rounded-3xl transition-transform transform scale-95 animate-fadeInUp">
            <div className="flex justify-center mb-6">
              <div className={`w-20 h-20 ${interviewSubmitted ? 'bg-indigo-500/20 border border-indigo-500/30' : 'bg-red-500/20 border border-red-500/30'} flex items-center justify-center rounded-full shadow-lg`}>
                {interviewSubmitted ? (
                  <svg className="w-12 h-12 text-indigo-400" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                ) : (
                  <svg className="w-12 h-12 text-red-400" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01M12 5a7 7 0 100 14 7 7 0 000-14z" />
                  </svg>
                )}
              </div>
            </div>
            <div className="text-center">
              <h2 className={`text-3xl font-semibold mb-3 ${interviewSubmitted ? 'text-gray-800/90' : 'text-red-500'}`}>
                {interviewSubmitted ? "Interview Completed!" : "Interview Not Submitted"}
              </h2>
              <p className="text-gray-700 text-lg mb-6">
                {interviewSubmitted ? (
                  <>
                    Your session lasted <strong className="text-gray-900">{formatTime(elapsedTime)}</strong>. You can now proceed to the next steps.
                  </>
                ) : (
                  <>Your interview was less than prescribed time and will not be submitted.</>
                )}
              </p>
            </div>
            <div className="flex justify-center">
              <button onClick={handleBackNavigation} className="px-8 py-4 text-lg font-semibold text-white bg-indigo-600 rounded-full shadow-lg hover:bg-indigo-500 transition-all duration-300">
                Continue
              </button>
            </div>
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-32 h-32 bg-white/10 blur-3xl opacity-40"></div>
          </div>
        </div>
      )}

      <div className="bg-black/90 fixed inset-0 flex flex-col">
        <div className="flex items-center h-16 px-5 border-b border-gray-800">
          <img className="h-6" src={faanglogoName} alt="Company Logo" />
          <div className="ml-auto bg-gray-800 text-white rounded-full px-4 py-1">
            Interview Session
          </div>
        </div>

        <div className="flex flex-1 overflow-hidden p-2 gap-2">
          <div className="relative flex-1 bg-[#131313] rounded-xl overflow-hidden">
            <div className="absolute top-4 right-4 z-10 bg-black/30 backdrop-blur-sm px-4 py-1 rounded-full text-white flex items-center gap-2">
              <span className="w-3 h-3 bg-red-500 rounded-full animate-pulse"></span>
              <span>Recording • {formatTime(elapsedTime)}</span>
            </div>
            <video ref={videoRef} autoPlay playsInline muted className="h-full w-full object-cover transform -scale-x-100" />
          </div>
          <div className="w-1/4 ml-4 h-full flex flex-col items-center justify-center bg-[#1d1d1d] rounded-2xl">
            <div
              className={`relative w-44 h-44 rounded-full overflow-hidden shadow-2xl border-4 transition-transform duration-300 ${isAgentTalking ? "border-indigo-500 animate-pulse" : "border-white/40"}`}
              style={{
                background: "rgba(255, 255, 255, 0.17)",
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(20px)",
                boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2), inset 0px 2px 6px rgba(255, 255, 255, 0.15)",
              }}
            >
              <div className="absolute inset-0 rounded-full border border-white/10 shadow-lg blur-md opacity-40"></div>
              <div className="absolute inset-0 bg-gradient-to-br from-white/10 via-white/5 to-transparent backdrop-blur-3xl rounded-full"></div>
              <div className="relative flex items-center justify-center w-full h-full">
                <img src={asendia_logo} alt="User Avatar" className="w-full h-full object-cover rounded-full transform scale-[0.92] hover:scale-[0.98] transition-all duration-300 ease-out" />
              </div>
            </div>
            <h3 className="mt-4 text-[18px] text-white/70 font-normal tracking-wide">Asendia Interviewer</h3>
          </div>
        </div>

        <div className="h-20 flex items-center justify-between px-8 border-t border-gray-800">
          <div className="text-gray-400">
            {new Date().toLocaleTimeString()} | AI Assessment Interview
            <div className="text-xs text-yellow-400 mt-1">Interview must be at least 10 minutes to submit</div>
          </div>
          <div className="flex gap-6">
            <div className="flex flex-col items-center">
              <button onClick={handleEndCallClick} className="w-12 h-12 bg-red-600 rounded-full flex items-center justify-center hover:bg-red-500 transition-colors">
                <MdCall className="text-white text-xl" />
              </button>
              <span className="text-xs text-gray-400 mt-1">Leave</span>
            </div>
            <div className="flex flex-col items-center">
              <button onClick={toggleMute} className="w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center hover:bg-gray-700 transition-colors">
                {isMicMuted ? <IoMdMicOff className="text-gray-400 text-xl" /> : <IoMdMic className="text-gray-400 text-xl" />}
              </button>
              <span className="text-xs text-gray-400 mt-1">{isMicMuted ? "Unmute" : "Mute"}</span>
            </div>
          </div>
        </div>

        {showEndCallModal && (
          <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="relative w-full max-w-lg p-9 bg-white/70 border border-white/20 shadow-2xl rounded-3xl transition-transform transform scale-95 animate-fadeInUp">
              <div className="text-center">
                <h2 className="text-3xl font-semibold text-gray-800/90 mb-4">
                  {elapsedTime < MINIMUM_INTERVIEW_DURATION ? "Interview Too Short" : "Are You Sure?"}
                </h2>
                <p className="text-gray-700 text-lg mb-6">
                  {elapsedTime < MINIMUM_INTERVIEW_DURATION
                    ? `Your interview must be at least 10 minutes long. If you end now, it will not be submitted.`
                    : "Your call is still active. If you leave now, you won’t be able to retake it."}
                </p>
              </div>
              <div className="flex justify-center gap-6">
                <button onClick={confirmEndCall} className="px-8 py-4 text-lg font-semibold text-white bg-red-600 rounded-xl shadow-xl hover:bg-red-500 transition-all duration-300">
                  {elapsedTime < MINIMUM_INTERVIEW_DURATION ? "End Interview" : "Yes, End Call"}
                </button>
                <button onClick={cancelEndCall} className="px-8 py-4 text-lg font-semibold text-gray-900 bg-gray-300 rounded-xl shadow-lg hover:bg-gray-400 transition-all duration-300">
                  Cancel
                </button>
              </div>
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-32 h-32 bg-white/10 blur-3xl opacity-40"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TalenthubVideoInterview;