// PublicProfilePage.jsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Briefcase,
  Code2,
  Linkedin,
  Mail,
  Phone,
  Globe,
  Clock,
  CircleDollarSign,
  Calendar,
  Video,
  MousePointerClick,
  User,
  Link,
  Bot,
  Play,
} from "lucide-react";

import asendialogo from "../../assests/Images/asendia_logo_text.png";
import { FiLink } from "react-icons/fi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Utility function to format ISO dates into a user-friendly format
const formatDate = (isoDate) => {
  if (!isoDate || isoDate === "Present") return "Present"; // Handle "Present" or invalid dates
  try {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short", // e.g., "Jan" instead of "January"
    }); // Returns e.g., "Jan 2022"
  } catch (error) {
    console.error("Error formatting date:", error);
    return isoDate; // Fallback to original if parsing fails
  }
};



// A reusable section card component for each content section.
const SectionCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-xl shadow-xs border border-gray-100 p-6 mb-6 transition-all hover:shadow-sm">
    <div className="flex items-center gap-3 mb-4">
      <div className="p-2 bg-indigo-600 rounded-lg text-gray-700">{icon}</div>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
    </div>
    <div className="space-y-4">{children}</div>
  </div>
);

// A simple navigation bar with your app brand identity.
const NavBar = () => {
  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 py-4 flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={asendialogo}
            alt="Asendia Logo"
            className="cursor-pointer block dark:hidden object-cover h-[23px] ml-3 mt-1"
          />
        </div>
      </div>
    </nav>
  );
};

const PublicProfilePage = () => {
  const { token } = useParams(); // Extract token from URL
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`https://jobseeker.asendia.ai/api/public-profile/${token}`)
      .then((response) => {
        setProfile(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching profile:", err);
        setError("Failed to load profile. Please try again later.");
        setLoading(false);
      });
  }, [token]);

  if (loading) return <div className="text-center p-10">Loading profile...</div>;
  if (error)
    return (
      <div className="text-center p-10 text-red-600">
        {error}
      </div>
    );
  if (!profile)
    return <div className="text-center p-10">Profile not found.</div>;

  const data = profile;

  const promoVideoUrl = data?.promo_video?.status === "Found" ? data.promo_video.url : null;
  // Use optional chaining and fallback to an empty object if not available
  const cv = data.full_cv || {};

  // Provide default empty arrays for collections
  const experience = cv.experience || [];
  const projects = cv.projects || [];
  const contact = cv.contact || {};
  const skills = cv.skills || {};
  const technicalSkills = skills.technical || [];
  const softSkills = skills.soft || [];
  const education = cv.education || [];

  // For self-promotional video summary
  const selfPromoSummary =
    (data.full_cv && data.full_cv.about_me) || (data.resumeSummary && data.resumeSummary.keyqualifications) ||
    (data.selfPromotionalVideo && data.selfPromotionalVideo.summary) ||
    "Full-stack developer with expertise in modern web technologies. Passionate about creating efficient, scalable applications with intuitive user experiences. Strong collaborator with experience leading development teams.";

  // Handle location with default values
  const locationCity = data.location?.city || "Unknown City";
  const locationCountry = data.location?.country || "Unknown Country";

  // For salary, availability, notice, and start options with fallbacks
  const salaryInfo = data.salary
    ? `${data.salary.currency || "$"}${data.salary.amount || "N/A"}/mo`
    : "N/A";
  const availability = data.availability || "N/A";
  const notice = data.weeksNotice ? `${data.weeksNotice} weeks notice` : "N/A";
  const startOption = data.startOption || "N/A";

  // For name and headline
  const profileName = cv.name || "No Name Provided";
  const headline = data.headline || "No Headline Provided";

  // For a public profile the share button simply copies the current URL.
  const handleShareProfile = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(window.location.href);
      toast.success(
        <div className="flex items-center gap-2">

          <span>Profile link copied to clipboard!</span>
        </div>
      );
    } else {
      alert("Clipboard API not supported");
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 font-inter">
      <div className="mb-2">
        <NavBar />
      </div>
      <div className="flex-1 overflow-y-auto max-w-6xl mx-auto p-4 sm:p-6 lg:p-8">
        {/* Profile Header */}
        <header className="bg-white rounded-2xl shadow-xs border border-gray-100 p-8 mb-8">
          <div className="flex flex-col md:flex-row gap-8 items-start justify-between">
            <div className="flex items-start gap-6 w-full">
              <div className="w-28 h-28 rounded-2xl bg-gray-50 border-2 border-gray-100 flex items-center justify-center">
                <User size={48} className="text-gray-400" />
              </div>
              <div className="flex-1 space-y-3">
                <div>
                  <h1 className="text-3xl font-bold text-gray-900 mb-1">
                    {profileName}
                  </h1>
                  <p className="text-lg text-gray-600 font-medium">{headline}</p>
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="bg-gray-50 px-3 py-1.5 rounded-full flex items-center gap-2">
                    <Globe size={16} className="text-gray-500" />
                    <span className="text-sm text-gray-600">
                      {locationCity}, {locationCountry}
                    </span>
                  </div>
                  {Object.entries({
                    salary: <CircleDollarSign size={16} />,
                    availability: <Clock size={16} />,
                    notice: <Calendar size={16} />,
                    start: <MousePointerClick size={16} />,
                  }).map(([key, icon]) => (
                    <div
                      key={key}
                      className="bg-gray-50 px-3 py-1.5 rounded-full flex items-center gap-2"
                    >
                      {icon}
                      <span className="text-sm text-gray-600">
                        {key === "salary"
                          ? salaryInfo
                          : key === "notice"
                            ? notice
                            : key === "start"
                              ? startOption
                              : availability}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Share Button in Header */}
            <div onClick={handleShareProfile} className="md:w-auto cursor-pointer">
              <button className="w-full flex items-center gap-2 px-4 py-2 bg-indigo-700 hover:bg-indigo-800 text-white rounded-xl transition-colors">
                <Link size={18} />
                Share
              </button>

            </div>
            <ToastContainer position="top-center" autoClose={3000} />
          </div>
        </header>

        {/* Main Content Grid */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Column */}
          <div className="lg:w-2/3 space-y-6">
            <SectionCard
              title="About Me"
              icon={<User size={20} className="text-gray-50" />}
            >
              <p className="text-gray-600 leading-relaxed">{selfPromoSummary}</p>
            </SectionCard>

            <SectionCard
              title="Experience"
              icon={<Briefcase size={20} className="text-gray-50" />}
            >
              {experience.length > 0 ? (
                experience.map((exp, index) => (
                  <div
                    key={index}
                    className="group relative pl-6 pb-6 border-l-2 border-gray-100 last:border-0 last:pb-0"
                  >
                    <div className="absolute w-2 h-2 bg-gray-400 rounded-full -left-[5px] top-0" />
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h4 className="text-lg font-semibold text-gray-900">
                          {exp.jobtitle || "No Job Title"}
                        </h4>
                        <p className="text-gray-600 font-medium">
                          {exp.company || "No Company"}
                        </p>
                      </div>
                      <span className="text-sm text-gray-500 whitespace-nowrap">
                        {formatDate(exp.startdate)} - {formatDate(exp.enddate)}
                      </span>
                    </div>
                    {exp.responsibilities && exp.responsibilities.length > 0 ? (
                      <ul className="list-disc pl-6 space-y-2">
                        {exp.responsibilities.map((res, i) => (
                          <li key={i} className="text-gray-600 leading-relaxed">
                            {res}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-gray-600">No responsibilities listed.</p>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No experience available.</p>
              )}
            </SectionCard>


            {/* Education Section (conditionally rendered) */}
            {education && education.length > 0 && (
              <SectionCard
                title="Education"
                icon={<Calendar size={20} className="text-gray-50" />}
              >
                {education.map((edu, index) => (
                  <div
                    key={index}
                    className="group relative pl-6 pb-6 border-l-2 border-gray-100 last:border-0 last:pb-0"
                  >
                    <div className="absolute w-2 h-2 bg-gray-400 rounded-full -left-[5px] top-0" />
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h4 className="text-lg font-semibold text-gray-900">
                          {edu.degree || "Degree not provided"}
                        </h4>
                        <p className="text-gray-600 font-medium">
                          {edu.institution || "Institution not provided"}
                        </p>
                      </div>
                      <span className="text-sm text-gray-500 whitespace-nowrap">
                        {formatDate(edu.startdate)} - {formatDate(edu.enddate)}
                      </span>
                    </div>
                    {edu.description && (
                      <p className="text-gray-600">{edu.description}</p>
                    )}
                  </div>
                ))}
              </SectionCard>
            )}

            <SectionCard
              title="Projects"
              icon={<Code2 size={20} className="text-gray-50" />}
            >
              {projects.length > 0 ? (
                <div className="grid gap-4">
                  {projects.map((project, index) => (
                    <div
                      key={index}
                      className="p-5 bg-white rounded-xl border border-gray-100"
                    >
                      <div className="flex justify-between items-start mb-3">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {project.projectname || "Unnamed Project"}
                        </h4>
                        <span className="text-sm text-gray-600 bg-gray-100 px-2 py-1 rounded-full">
                          {project.role || "N/A"}
                        </span>
                      </div>
                      <p className="text-gray-600 mb-4">
                        {project.description || "No description provided."}
                      </p>
                      {project.technologies && project.technologies.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                          {project.technologies.map((tech, i) => (
                            <span
                              key={i}
                              className="px-3 py-1 bg-indigo-100 text-gray-700 text-sm rounded-full"
                            >
                              {tech}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-600">No technologies listed.</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-600">No projects available.</p>
              )}
            </SectionCard>
          </div>

          {/* Right Column */}
          <div className="lg:w-1/3 space-y-6">
            <SectionCard
              title="Contact"
              icon={<Mail size={20} className="text-gray-50" />}
            >
              <div className="space-y-4">
                {/* {contact.email ? (
                  <div className="flex items-center gap-3">
                    <Mail size={16} className="text-gray-500" />
                    <a
                      href={`mailto:${contact.email}`}
                      className="text-gray-700 hover:text-gray-900"
                    >
                      {contact.email}
                    </a>
                  </div>
                ) : (
                  <p className="text-gray-600">Email not provided.</p>
                )} */}
                {/* {contact.phone ? (
                  <div className="flex items-center gap-3">
                    <Phone size={16} className="text-gray-500" />
                    <a
                      href={`tel:${contact.phone}`}
                      className="text-gray-700 hover:text-gray-900"
                    >
                      {contact.phone}
                    </a>
                  </div>
                ) : (
                  <p className="text-gray-600">Phone not provided.</p>
                )} */}
                {contact.linkedin ? (
                  <div className="flex items-center gap-3">
                    <Linkedin size={16} className="text-gray-500" />
                    <a
                      href={contact.linkedin}
                      className="text-gray-700 hover:text-gray-900"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn Profile
                    </a>
                  </div>
                ) : (
                  <p className="text-gray-600">LinkedIn not provided.</p>
                )}
              </div>
            </SectionCard>

            <SectionCard
              title="Media"
              icon={<Video size={20} className="text-gray-50" />}
            >
              <div className="grid gap-4">
                <div className="group relative aspect-video bg-gradient-to-r from-indigo-50 to-indigo-50 rounded-xl overflow-hidden">
                  {promoVideoUrl ? (
                    <video controls className="w-full h-full object-cover">
                      <source src={promoVideoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <button className="absolute inset-0 flex flex-col items-center justify-center gap-2 p-4 hover:bg-gray-900/10 transition-colors">
                      <div className="p-3 bg-white rounded-full shadow-sm group-hover:shadow-md transition-shadow">
                        <Play size={20} className="text-indigo-600" />
                      </div>
                      <span className="font-medium text-gray-900">Video Introduction</span>
                      <p className="text-sm text-gray-600 text-center">2:45 preview</p>
                    </button>
                  )}
                </div>
              </div>
            </SectionCard>

            <SectionCard
              title="Skills"
              icon={<Code2 size={20} className="text-gray-50" />}
            >
              <div className="flex flex-wrap gap-2">
                {technicalSkills.length > 0 ? (
                  technicalSkills.map((skill, i) => (
                    <span
                      key={`tech-${i}`}
                      className="px-3 py-1 bg-indigo-50 text-gray-700 text-sm rounded-full"
                    >
                      {skill}
                    </span>
                  ))
                ) : (
                  <p className="text-gray-600">No technical skills listed.</p>
                )}
                {softSkills.length > 0 ? (
                  softSkills.map((skill, i) => (
                    <span
                      key={`soft-${i}`}
                      className="px-3 py-1 bg-indigo-50 text-gray-700 text-sm rounded-full"
                    >
                      {skill}
                    </span>
                  ))
                ) : (
                  <p className="text-gray-600">No soft skills listed.</p>
                )}
              </div>
            </SectionCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicProfilePage;
