import { useState, useEffect } from "react";
import { X, Upload, Video, CheckCircle, Sparkles, AlertCircle, Loader2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { fetchProfileData } from "../../Redux/Slices/profileSlice";
import { uploadPromoVideo } from "./UploadPromoVideo";

export default function VideoUploadModal({ isOpen, onClose, user_id }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progressData, setProgressData] = useState({ percent: 0, estimatedTimeRemaining: 0 });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [uploadAbortController, setUploadAbortController] = useState(null);
  const dispatch = useDispatch();

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size < 1024 * 1024) return (size / 1024).toFixed(2) + " KB";
    else if (size < 1024 * 1024 * 1024) return (size / (1024 * 1024)).toFixed(2) + " MB";
    else return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  };

  const handleFileChange = (event) => {
    if (uploading || successMessage) return;

    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["video/mp4", "video/quicktime"];
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please upload an MP4 or MOV file.");
        setSelectedFile(null);
        return;
      }
      if (file.size > 100 * 1024 * 1024) {
        setError("File size exceeds 100MB. Please choose a smaller file.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      setError(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(null);
      setUploading(false);
      setProgressData({ percent: 0, estimatedTimeRemaining: 0 });
      setError(null);
      setSuccessMessage("");
      setUploadAbortController(null);
    }
  }, [isOpen]);

  const handleUpload = async () => {
    if (successMessage) return;

    if (!selectedFile) {
      setError("Please select a file first.");
      return;
    }

    setUploading(true);
    setError(null);
    setSuccessMessage("");
    setProgressData({ percent: 0, estimatedTimeRemaining: 0 });

    const controller = new AbortController();
    setUploadAbortController(controller);

    try {
      const response = await uploadPromoVideo(
        selectedFile,
        (progress) => setProgressData(progress),
        controller.signal
      );
      setSuccessMessage("Video uploaded successfully!");
      dispatch(fetchProfileData());
    } catch (err) {
      if (err.name === "AbortError") {
        setError("Upload canceled.");
      } else {
        setError("Upload failed. Please try again.");
      }
    } finally {
      setUploading(false);
      setUploadAbortController(null);
    }
  };

  const handleCancel = () => {
    if (uploading && uploadAbortController) {
      uploadAbortController.abort();
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 backdrop-blur-md">
      <div className="fixed inset-0 bg-black/40 flex items-center justify-center p-4">
        <div 
          className="bg-white rounded-2xl shadow-2xl w-full max-w-3xl transform transition-all duration-300 overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex justify-between items-center px-8 py-6 border-b border-gray-100">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-indigo-50 rounded-xl">
                <Video className="h-7 w-7 text-indigo-600" />
              </div>
              <div>
                <h2 className="text-2xl font-semibold text-gray-900">
                  Upload Promo Video
                </h2>
                <p className="text-base text-gray-500 mt-1">Make your first impression count</p>
              </div>
            </div>
            <button
              onClick={handleCancel}
              className="p-2 hover:bg-gray-50 rounded-xl transition-colors"
            >
              <X className="h-6 w-6 text-gray-400 hover:text-gray-600" />
            </button>
          </div>

          {/* Main Content */}
          <div className="p-8">
            {/* Guidelines */}
            <div className="grid grid-cols-3 gap-6 mb-8">
              <div className="p-4 bg-gray-50 rounded-xl">
                <div className="flex items-start gap-3">
                  <CheckCircle className="h-6 w-6 text-green-500 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-medium text-gray-900 mb-1">Video Format</h3>
                    <p className="text-sm text-gray-600">30-second vertical video (9:16 ratio)</p>
                  </div>
                </div>
              </div>
              <div className="p-4 bg-gray-50 rounded-xl">
                <div className="flex items-start gap-3">
                  <Sparkles className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-medium text-gray-900 mb-1">Content</h3>
                    <p className="text-sm text-gray-600">Show personality & career aspirations</p>
                  </div>
                </div>
              </div>
              <div className="p-4 bg-gray-50 rounded-xl">
                <div className="flex items-start gap-3">
                  <AlertCircle className="h-6 w-6 text-indigo-500 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="font-medium text-gray-900 mb-1">File Requirements</h3>
                    <p className="text-sm text-gray-600">MP4 or MOV format, max 100MB</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Upload Area */}
            <div className="group">
              <label
                htmlFor="video-upload"
                className={`flex flex-col items-center justify-center w-full p-12 border-2 border-dashed rounded-xl cursor-pointer transition-all
                  ${selectedFile ? "border-indigo-200 bg-indigo-50/50" : "border-gray-200 hover:border-indigo-300 bg-gray-50"}
                  ${uploading || successMessage ? "pointer-events-none opacity-70" : ""}`}
              >
                <div className="flex flex-col items-center space-y-4">
                  <div className="p-4 bg-white rounded-full shadow-sm border">
                    <Upload
                      className={`h-8 w-8 transition-colors ${
                        selectedFile ? "text-indigo-600" : "text-gray-400"
                      }`}
                    />
                  </div>
                  <div className="text-center">
                    {selectedFile ? (
                      <>
                        <p className="text-lg font-medium text-indigo-600">{selectedFile.name}</p>
                        <p className="text-sm text-gray-500 mt-1">Size: {formatFileSize(selectedFile.size)}</p>
                      </>
                    ) : (
                      <>
                        <p className="text-lg font-medium text-gray-700">
                          Drag & drop your video here
                        </p>
                        <p className="text-sm text-gray-500 mt-2">
                          or <span className="text-indigo-600 font-medium">browse files</span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </label>
              <input
                id="video-upload"
                type="file"
                className="hidden"
                accept="video/mp4,video/quicktime"
                onChange={handleFileChange}
                disabled={uploading || successMessage}
              />
            </div>

            {/* Progress and Messages */}
            {uploading && (
              <div className="mt-6 px-4">
                <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-indigo-600 rounded-full transition-all duration-300"
                    style={{ width: `${progressData.percent}%` }}
                  />
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p className="text-sm text-gray-600">Uploading...</p>
                  <div className="text-right">
                    <p className="text-sm font-medium text-gray-900">{progressData.percent}%</p>
                    {progressData.estimatedTimeRemaining > 0 && (
                      <p className="text-xs text-gray-500">
                        {progressData.estimatedTimeRemaining}s remaining
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {error && (
              <div className="mt-4 p-4 bg-red-50 rounded-lg">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            )}
            
            {successMessage && (
              <div className="mt-4 p-4 bg-green-50 rounded-lg">
                <p className="text-sm text-green-600">{successMessage}</p>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="px-8 py-6 bg-gray-50 flex justify-end gap-4">
            <button
              onClick={handleCancel}
              className="px-6 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              {uploading ? "Cancel Upload" : "Cancel"}
            </button>
            <button
              onClick={handleUpload}
              disabled={!selectedFile || uploading || successMessage}
              className={`px-6 py-2.5 text-sm font-medium rounded-lg transition-all flex items-center justify-center gap-2
                ${
                  selectedFile && !uploading && !successMessage
                    ? "bg-indigo-600 text-white hover:bg-indigo-700 shadow-md"
                    : "bg-indigo-400 text-white cursor-not-allowed"
                }`}
            >
              {uploading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Uploading...
                </>
              ) : (
                "Upload Video"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}