import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateOnboardingStep, updateTalentHubProfile } from '../../Services/ApiEnpoints';
import { setOnboardingStep } from '../../Redux/Slices/userSlice';
import { BsArrowLeft, BsExclamationCircle, BsQuestionCircle, BsGear, BsShieldLock, BsCloud, BsGraphUp, BsCodeSlash, BsBuilding, BsServer, BsPerson, BsMegaphone, BsBox, BsCash } from 'react-icons/bs';
import { motion } from 'framer-motion';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import NavBar from './components/NavBar';
import Stepper from './components/Stepper';
import { Check } from 'lucide-react';

// Custom React Select styles (unchanged)
const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '1rem',
    borderColor: state.isFocused ? '#6366F1' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 2px rgba(99,102,241,0.2)' : provided.boxShadow,
    minHeight: '50px',
    padding: '2px',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: state.isFocused ? '#6366F1' : '#A5B4FC',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6B7280',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#6366F1' : state.isFocused ? '#E0E7FF' : provided.backgroundColor,
    color: state.isSelected ? '#fff' : '#374151',
    borderRadius: '0.5rem',
    padding: '8px 12px',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#4F46E5',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
};

const OnboardingStep2 = ({ setFeedback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.user) || {};

  // Loading states
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);

  // Step & error management
  const [formStep, setFormStep] = useState(0);
  const totalSteps = 7; // Updated to 7 steps
  const [error, setError] = useState("");

  // Location data
  const [countries, setCountries] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  // Selected items
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [useManualCity, setUseManualCity] = useState(false);
  const [useManualState, setUseManualState] = useState(false);

  // New state variables
  const [selectedRole, setSelectedRole] = useState(""); // Now a string instead of object
  const [experienceLevel, setExperienceLevel] = useState("");
  const [selectedCompanyTypes, setSelectedCompanyTypes] = useState([]);

  // Existing form fields
  const [availability, setAvailability] = useState("");
  const [salary, setSalary] = useState("");
  const [startOption, setStartOption] = useState("");
  const [weeksNotice, setWeeksNotice] = useState("");

  // Role options with icons
  const roleOptions = [
    { value: "AI & Machine Learning Engineer", label: "AI & Machine Learning Engineer", icon: <BsGear /> },
    { value: "Cybersecurity Engineer / Ethical Hacker", label: "Cybersecurity Engineer / Ethical Hacker", icon: <BsShieldLock /> },
    { value: "Cloud Solutions Architect", label: "Cloud Solutions Architect", icon: <BsCloud /> },
    { value: "Data Scientist / Data Engineer", label: "Data Scientist / Data Engineer", icon: <BsGraphUp /> },
    { value: "Software Engineer (Frontend, Backend, Full-Stack)", label: "Software Engineer (Frontend, Backend, Full-Stack)", icon: <BsCodeSlash /> },
    { value: "Solutions Architect", label: "Solutions Architect", icon: <BsBuilding /> },
    { value: "AI Data Center Specialist", label: "AI Data Center Specialist", icon: <BsServer /> },
    { value: "AI Consultant", label: "AI Consultant", icon: <BsPerson /> },
    { value: "Sales & Business Development Manager", label: "Sales & Business Development Manager", icon: <BsMegaphone /> },
    { value: "Marketing & Growth Strategist", label: "Marketing & Growth Strategist", icon: <BsMegaphone /> },
    { value: "Product Manager", label: "Product Manager", icon: <BsBox /> },
    { value: "Finance & Investment Analyst", label: "Finance & Investment Analyst", icon: <BsCash /> },
  ];

  const experienceOptions = [
    { value: "junior", label: "Junior (0-2 years experience)" },
    { value: "mid", label: "Mid-Level (3-6 years experience)" },
    { value: "senior", label: "Senior (7+ years experience)" },
  ];

  const companyTypeOptions = [
    "AI Startup",
    "Big Tech Companies",
    "Consulting Firm",
    "Freelance / Contractor",
  ];

  // Fetch countries (unchanged)
  useEffect(() => {
    async function fetchCountries() {
      try {
        setLoadingCountries(true);
        const response = await fetch("https://countriesnow.space/api/v0.1/countries/iso");
        const data = await response.json();
        if (!data.error && data.data) {
          const mapped = data.data.map((c) => ({ value: c.name, label: c.name }));
          setCountries(mapped);
        }
      } catch (err) {
        console.error("Failed to fetch countries:", err);
      } finally {
        setLoadingCountries(false);
      }
    }
    fetchCountries();
  }, []);

  // Fetch states (unchanged)
  useEffect(() => {
    async function fetchStates() {
      try {
        if (!selectedCountry) return;
        setStatesList([]);
        setSelectedState(null);
        setUseManualState(false);
        setCitiesList([]);
        setSelectedCity(null);
        setUseManualCity(false);
        setLoadingStates(true);
        const response = await fetch("https://countriesnow.space/api/v0.1/countries/states", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ country: selectedCountry.value }),
        });
        const data = await response.json();
        if (!data.error && data.data && data.data.states) {
          const mapped = data.data.states.map((s) => ({ value: s.name, label: s.name }));
          setStatesList(mapped);
        }
      } catch (err) {
        console.error("Failed to fetch states:", err);
      } finally {
        setLoadingStates(false);
      }
    }
    fetchStates();
  }, [selectedCountry]);

  // Fetch cities (unchanged)
  useEffect(() => {
    async function fetchCities() {
      try {
        if (!selectedState || !selectedCountry) return;
        setCitiesList([]);
        setSelectedCity(null);
        setUseManualCity(false);
        setLoadingCities(true);
        const response = await fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            country: selectedCountry.value,
            state: useManualState ? selectedState : selectedState.value,
          }),
        });
        const data = await response.json();
        if (!data.error && Array.isArray(data.data)) {
          const mapped = data.data.map((city) => ({ value: city, label: city }));
          setCitiesList(mapped);
          if (mapped.length === 0) setUseManualCity(true);
        } else {
          setUseManualCity(true);
        }
      } catch (err) {
        console.error("Failed to fetch cities:", err);
        setUseManualCity(true);
      } finally {
        setLoadingCities(false);
      }
    }
    fetchCities();
  }, [selectedCountry, selectedState, useManualState]);

  // Validate current step
  const validateCurrentStep = useCallback(() => {
    if (formStep === 0) {
      const cityVal = useManualCity ? selectedCity : selectedCity?.value;
      const stateVal = useManualState ? selectedState : selectedState?.value;
      if (!selectedCountry?.value || !stateVal || !cityVal) {
        setError("Please select your country, state, and city.");
        return false;
      }
    } else if (formStep === 1) {
      if (!selectedRole) {
        setError("Please select your role.");
        return false;
      }
    } else if (formStep === 2) {
      if (!experienceLevel) {
        setError("Please select your experience level.");
        return false;
      }
    } else if (formStep === 3) {
      if (selectedCompanyTypes.length === 0) {
        setError("Please select at least one company type.");
        return false;
      }
    } else if (formStep === 4 && !availability) {
      setError("Please select your work availability.");
      return false;
    } else if (formStep === 5 && (!salary || Number(salary) <= 0)) {
      setError("Please enter a valid salary expectation.");
      return false;
    } else if (formStep === 6) {
      if (!startOption) {
        setError("Please select when you can start working.");
        return false;
      }
      if (startOption === "notice" && (!weeksNotice || Number(weeksNotice) <= 0)) {
        setError("Please enter a valid number of weeks notice.");
        return false;
      }
    }
    setError("");
    return true;
  }, [
    formStep,
    selectedCountry,
    selectedState,
    selectedCity,
    useManualCity,
    useManualState,
    selectedRole,
    experienceLevel,
    selectedCompanyTypes,
    availability,
    salary,
    startOption,
    weeksNotice,
  ]);

  // Step navigation
  const handleNext = () => {
    if (!validateCurrentStep()) return;
    if (formStep < totalSteps - 1) {
      setFormStep(formStep + 1);
    }
  };


  const toggleCompanyType = (type) => {
    if (selectedCompanyTypes.includes(type)) {
      setSelectedCompanyTypes(selectedCompanyTypes.filter((t) => t !== type));
    } else {
      setSelectedCompanyTypes([...selectedCompanyTypes, type]);
    }
  };

  const handleBack = () => {
    if (formStep > 0) {
      setFormStep(formStep - 1);
      setError("");
    }
  };

  // Complete profile
  const handleCompleteProfile = async () => {
    if (!validateCurrentStep()) return;
    const cityVal = useManualCity ? selectedCity : selectedCity?.value;
    const stateVal = useManualState ? selectedState : selectedState?.value;
    const formData = {
      location: `${cityVal}, ${stateVal}, ${selectedCountry?.value}`,
      role: selectedRole,
      experienceLevel,
      companyTypes: selectedCompanyTypes,
      availability,
      salary: Number(salary),
      startOption,
      weeksNotice: startOption === "notice" ? Number(weeksNotice) : null,
    };

    try {
      setOverallLoading(true);
      await updateOnboardingStep("3", email);
      dispatch(setOnboardingStep("3"));
      await updateTalentHubProfile(formData);
      navigate("/signup/onboarding-step3");
    } catch (err) {
      console.error("Failed to update profile:", err);
      setError("Failed to complete profile. Please try again later.");
    } finally {
      setOverallLoading(false);
    }
  };

  // Render form steps
  const renderFormStep = () => {
    if (formStep === 0) {
      return (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2 }} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">Country</label>
            <Select
              options={countries}
              value={selectedCountry}
              onChange={(val) => setSelectedCountry(val)}
              placeholder="Select Country"
              styles={reactSelectStyles}
              isLoading={loadingCountries}
              isClearable
            />
            {loadingCountries && <div className="mt-1 flex items-center gap-2 text-sm text-gray-500"><ClipLoader color="#6366F1" size={15} /><span>Loading countries...</span></div>}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">State</label>
            {useManualState ? (
              <input
                type="text"
                value={typeof selectedState === 'string' ? selectedState : ''}
                onChange={(e) => setSelectedState(e.target.value)}
                placeholder="Enter state"
                className="w-full border-2 border-gray-200 rounded-xl px-4 py-3 text-base focus:outline-none focus:border-indigo-500"
              />
            ) : (
              <Select
                options={statesList}
                value={selectedState}
                onChange={(val) => setSelectedState(val)}
                placeholder="Select State"
                styles={reactSelectStyles}
                isLoading={loadingStates}
                isClearable
                isDisabled={!selectedCountry || statesList.length === 0}
              />
            )}
            {loadingStates && <div className="mt-1 flex items-center gap-2 text-sm text-gray-500"><ClipLoader color="#6366F1" size={15} /><span>Loading states...</span></div>}
            {statesList.length > 0 && (
              <button type="button" onClick={() => setUseManualState(!useManualState)} className="mt-2 text-sm text-blue-500 underline">
                {useManualState ? 'Select from list' : "Can't find your state? Enter manually"}
              </button>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">City</label>
            {useManualCity ? (
              <input
                type="text"
                value={typeof selectedCity === 'string' ? selectedCity : ''}
                onChange={(e) => setSelectedCity(e.target.value)}
                placeholder="Enter city"
                className="w-full border-2 border-gray-200 rounded-xl px-4 py-3 text-base focus:outline-none focus:border-indigo-500"
              />
            ) : (
              <Select
                options={citiesList}
                value={selectedCity}
                onChange={(val) => setSelectedCity(val)}
                placeholder="Select City"
                styles={reactSelectStyles}
                isLoading={loadingCities}
                isClearable
                isDisabled={!selectedState || citiesList.length === 0}
              />
            )}
            {loadingCities && <div className="mt-1 flex items-center gap-2 text-sm text-gray-500"><ClipLoader color="#6366F1" size={15} /><span>Loading cities...</span></div>}
            {citiesList.length > 0 && (
              <button type="button" onClick={() => setUseManualCity(!useManualCity)} className="mt-2 text-sm text-blue-500 underline">
                {useManualCity ? 'Select from list' : "Can't find your city? Enter manually"}
              </button>
            )}
          </div>
        </motion.div>
      );
    } else if (formStep === 1) {
      return (
        <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="p-6 bg-white rounded-2xl shadow-sm"
      >
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Select your role</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {roleOptions.map((role) => (
            <motion.div
              key={role.value}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setSelectedRole(role.value)}
              className={`p-4 rounded-xl cursor-pointer transition-all duration-200 flex items-center gap-3
                ${selectedRole === role.value 
                  ? 'bg-indigo-50 border-2 border-indigo-500 shadow-sm' 
                  : 'bg-white border-2 border-gray-200 hover:bg-gray-100'
                }`}
            >
              <div className={`p-2 rounded-lg ${selectedRole === role.value ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-50 text-indigo-400'}`}>
                {role.icon}
              </div>
              <p className={`font-medium ${selectedRole === role.value ? 'text-indigo-700' : 'text-gray-700'}`}>
                {role.label}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>
      );
    } else if (formStep === 2) {
      return (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2 }}>
          <label className="block text-sm font-medium text-gray-700 mb-3">Experience Level</label>
          <div className="space-y-3">
            {experienceOptions.map((option) => (
              <label
                key={option.value}
                className={`flex items-center space-x-3 p-4 border-2 border-gray-200 rounded-xl cursor-pointer transition-all duration-200 ${experienceLevel === option.value ? 'border-indigo-500 bg-indigo-50/50' : 'border-gray-200 hover:border-gray-300'
                  }`}
              >
                <input
                  type="radio"
                  name="experienceLevel"
                  value={option.value}
                  checked={experienceLevel === option.value}
                  onChange={() => setExperienceLevel(option.value)}
                  className="h-4 w-4 text-indigo-600 border-2 border-gray-300 focus:ring-indigo-500"
                />
                <span className="text-gray-700">{option.label}</span>
              </label>
            ))}
          </div>
        </motion.div>
      );
    } else if (formStep === 3) {
      return (
        <motion.div 
      initial={{ opacity: 0, y: 10 }} 
      animate={{ opacity: 1, y: 0 }} 
      transition={{ duration: 0.3 }}
      className="rounded-xl bg-white"
    >
      <label className="block text-sm font-medium text-gray-700 mb-3">Preferred Company Type</label>
      
      <div className="space-y-3">
        {companyTypeOptions.map((type) => (
          <motion.div
            key={type}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <button
              onClick={() => toggleCompanyType(type)}
              className={`w-full flex items-center space-x-3 p-4 border-2 border-gray-200 rounded-xl transition-all ${
                selectedCompanyTypes.includes(type)
                  ? "border-blue-500 bg-blue-50 text-blue-700"
                  : "border-gray-200 hover:border-gray-300 text-gray-700"
              }`}
            >
              <span className="font-medium">{type}</span>
              {selectedCompanyTypes.includes(type) && (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                  className="bg-blue-500 text-white rounded-full p-1"
                >
                  <Check size={16} />
                </motion.div>
              )}
            </button>
          </motion.div>
        ))}
      </div>
      
      {selectedCompanyTypes.length > 0 && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          className="mt-6 pt-4 border-t border-gray-100"
        >
          <p className="text-sm font-medium text-gray-700">
            Selected: <span className="text-blue-600">{selectedCompanyTypes.join(", ")}</span>
          </p>
        </motion.div>
      )}
    </motion.div>
      );
    } else if (formStep === 4) {
      return (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2 }}>
          <label className="block text-sm font-medium text-gray-700 mb-3">What’s your work availability?</label>
          <div className="space-y-3">
            {['full-time', 'part-time', 'flexible'].map((option) => (
              <label
                key={option}
                className={`flex items-center space-x-3 p-4 border-2 rounded-xl cursor-pointer transition-all duration-200 ${availability === option ? 'border-indigo-500 bg-indigo-50/50' : 'border-gray-200 hover:border-gray-300'
                  }`}
              >
                <input
                  type="radio"
                  name="availability"
                  value={option}
                  checked={availability === option}
                  onChange={(e) => setAvailability(e.target.value)}
                  className="h-4 w-4 text-indigo-600 border-2 border-gray-300 focus:ring-indigo-500"
                />
                <div className="flex-1">
                  <p className="font-medium text-gray-900">
                    {option === 'full-time' ? 'Full time' : option === 'part-time' ? 'Part time' : 'I’m flexible'}
                  </p>
                  <p className="text-gray-500 text-sm mt-1">
                    {option === 'full-time'
                      ? 'Available for full-time roles'
                      : option === 'part-time'
                        ? 'Available for part-time roles'
                        : 'Open to both full-time and part-time roles'}
                  </p>
                </div>
              </label>
            ))}
          </div>
        </motion.div>
      );
    } else if (formStep === 5) {
      return (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2 }}>
          <label className="block text-sm font-medium text-gray-700 mb-3">Monthly Salary Expectation (USD)</label>
          <div className="relative">
            <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">$</span>
            <input
              type="number"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              placeholder="e.g. 2500"
              className="w-full border-2 border-gray-200 rounded-xl pl-9 pr-4 py-3 text-base focus:outline-none focus:border-indigo-500"
            />
          </div>
        </motion.div>
      );
    } else if (formStep === 6) {
      return (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2 }}>
          <label className="block text-sm font-medium text-gray-700 mb-3">When can you start working?</label>
          <div className="grid grid-cols-2 gap-4">
            {['immediate', 'notice'].map((option) => (
              <div
                key={option}
                onClick={() => setStartOption(option)}
                className={`p-4 border-2 rounded-xl cursor-pointer transition-all duration-200 ${startOption === option ? 'border-indigo-500 bg-indigo-50/50' : 'border-gray-200 hover:border-gray-300'
                  }`}
              >
                <p className="font-medium text-gray-900 text-center">
                  {option === 'immediate' ? 'I can start within 24 hours' : 'Weeks of notice'}
                </p>
              </div>
            ))}
          </div>
          {startOption === "notice" && (
            <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.2 }} className="mt-6">
              <label className="block text-sm font-medium text-gray-700 mb-3">How many weeks of notice?</label>
              <input
                type="number"
                value={weeksNotice}
                onChange={(e) => setWeeksNotice(e.target.value)}
                placeholder="e.g. 2"
                className="w-full border-2 border-gray-200 rounded-xl px-4 py-3 text-base focus:outline-none focus:border-indigo-500"
              />
            </motion.div>
          )}
        </motion.div>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-white overflow-auto">
      <NavBar
        links={[
          {
            label: (
              <span>
                <BsQuestionCircle className="inline mr-1" /> Submit feedback
              </span>
            ),
            onClick: () => setFeedback(true),
          },
        ]}
      />
      <div className="-mt-5">
        <Stepper currentStep={1} />
      </div>
      <div className="flex flex-col items-center justify-center px-4 sm:px-6 py-20 -mt-14">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: 1,
            y: 0,
            maxWidth: formStep === 1 ? '52rem' : '36rem', // Corresponds to max-w-3xl and max-w-xl
          }}
          transition={{
            opacity: { duration: 0.5, ease: "easeOut" },
            y: { duration: 0.5, ease: "easeOut" },
            maxWidth: { duration: 0.4, ease: "easeOut" }, // Smooth transition for maxWidth
          }}
          className="w-full bg-white rounded-3xl shadow-lg border border-gray-300/60 p-6 sm:p-10 relative"
        >
          <button
            className="text-gray-600 hover:text-gray-800 mb-6 flex items-center gap-2"
            onClick={() => navigate("/signup/onboarding-step1")}
          >
            <BsArrowLeft className="inline-block" />
            <span className="text-sm font-medium">Back</span>
          </button>
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-2">Complete Your Profile</h1>
            <p className="text-gray-600 text-sm">Answer a few quick questions to help us get to know you better.</p>
          </div>
          <div className="space-y-8">{renderFormStep()}</div>
          {error && (
            <div className="mt-6 flex items-center gap-2 text-red-600">
              <BsExclamationCircle className="flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          )}
          <div className="mt-8 flex justify-between items-center gap-4">
            {formStep > 0 && (
              <button
                onClick={handleBack}
                className="py-2.5 px-5 rounded-xl font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 text-sm"
              >
                Back
              </button>
            )}
            <div className="ml-auto flex gap-3">
              {formStep < totalSteps - 1 ? (
                <button
                  onClick={handleNext}
                  className="py-2.5 px-6 rounded-xl font-semibold text-white bg-gradient-to-r from-indigo-600 to-indigo-500 hover:from-indigo-700 hover:to-indigo-600 text-sm shadow-sm"
                >
                  Continue
                </button>
              ) : (
                <button
                  onClick={handleCompleteProfile}
                  disabled={overallLoading}
                  className={`py-2.5 px-6 rounded-xl font-semibold text-white bg-gradient-to-r from-indigo-600 to-indigo-500 hover:from-indigo-700 hover:to-indigo-600 text-sm shadow-sm ${overallLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {overallLoading ? (
                    <span className="flex items-center">
                      <ClipLoader color="#ffffff" size={16} className="mr-2" />
                      Processing...
                    </span>
                  ) : (
                    "Complete Profile"
                  )}
                </button>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default OnboardingStep2;